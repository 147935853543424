import { useConfirm } from "material-ui-confirm";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { useColumnDivider } from "../../../../export/cross-skilling";
import { updateWorkerSkillLevel } from "../../../../export/workers";
import { hasPermission } from "../../../../ts/utils/helpers";
import useColumnSelector from "../../../design/column-selector/controllers/use-column-selector";
import {
  addSkillInterested,
  fetchManageWorkersData,
  fetchNotAssignedSkillLevel,
  fetchSkillMatrixDefaultFilters, // TODO: For integrating dedicated skill matrix worker data
  fetchSkillMatrixFilters,
  resetSkillLevelStatus,
  resetSkillMatrixDefaultFilters,
  resetSkillMatrixFilter,
} from "../../../features/ManageWorkers/ManageWorkersSlice";
import { getLowestLevelObject } from "../../../utils/helpers";
import useViewport from "../../../utils/useViewport";
import useCrossSkillColumn from "./use-cross-skill-column";
import useCrossSkillData from "./use-cross-skill-data";
import useCrossSkillFilterController from "./use-cross-skill-filter-controller";

const useCrossSkillController = () => {
  const {
    allWorkers,
    allJobRoles,
    fetchSkillLevelsStatus,
    allJobs,
    fetchJobsByUserIdStatus,
    workerTypes,
    fetchManageWorkersDataStatus,
    allSkills,
    allSkillLevels,
    allLocations,
    allWorkCenters,
    allShiftPatterns,
    updateWorkerSkillLevelStatus,
    fullTimeSchedule,
    allOrganizations,
    allOrganizationTypes,
    allManagers,
    skillPriorities,
    fetchAllOrganizationsStatus,
    fetchAllLocationsStatus,
    fetchAllManagersStatus,
    skillMatrixFiltersStatus,
    skillMatrixDefaultFiltersStatus,
    skillMatrixAppliedFilters,
    skillInsightsEnabled,
    isSkillPriorityEnabled,
    companyId,
    userRolesWithPermission,
    notAssignedSkillLevel,
  } = useCrossSkillData();

  const dispatch = useDispatch();
  const { width } = useViewport();
  const containerRef = useRef();

  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [dataProcessed, setDataProcessed] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filterStatus, setFilterStatus] = useState(false);
  const [selectedWorkCenter, setSelectedWorkCenter] = useState([]);
  const [gotOrgs, setGotOrgs] = useState(false);
  const [totalWorkers, setTotalWorkers] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [fixedColumns, setFixedColumns] = useState([
    "companyEmployeeId",
    "jobTitle",
    "name",
    "managerName",
    "workCenter",
    "shiftPatternName",
    "skillScore",
  ]);
  const defaultCheckedColumns = ["skillScore", "name"];
  const [finalPickerColumn, setFinalPickerColumn] = useState();

  const confirm = useConfirm();
  const changeFilters = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);
  const [filterControllerProps, filterControllerEvents] =
    useCrossSkillFilterController({
      filters,
      changeFilters,
      allJobRoles,
      allSkills,
      allOrganizations,
      allWorkCenters,
      allShiftPatterns,
      allLocations,
      allManagers,
      workerTypes,
      skillPriorities,
      skillMatrixAppliedFilters,
    });

  const { filteredJobs } = filterControllerProps;
  const handleSkillLevels = (params, skill, level, setState) => {
    const obj = {
      skillId: skill.id,
      levelId: level.levelId,
      workerId: params.id,
    };

    const workerSkill = params.row.skills.find(
      (x) => parseInt(x.skillId) === parseInt(skill.id)
    );
    if (workerSkill) {
      if (workerSkill.levelId !== level.levelId) {
        confirm({
          title: "Are you sure?",
          description: `You are updating skill level from ${workerSkill.levelName} to ${level.name} for ${params.row.firstName} in ${workerSkill.skillName}.`,
        })
          .then(() => {
            dispatch(updateWorkerSkillLevel(obj));
            setState((state) => ({
              ...state,
              level: level.level,
              levelId: level.levelId,
              repCode: level.repCode,
              backColorCode: level.backColorCode,
              foreColorCode: level.foreColorCode,
              value: level.value,
            }));
          })
          .catch(() => {
            /* ... */
          });
      }
    } else {
      confirm({
        title: "Are you sure?",
        description: `You are adding ${skill.name} with level ${level.name} for ${params.row.firstName}.`,
      })
        .then(() => {
          dispatch(updateWorkerSkillLevel(obj));
          setState((state) => ({
            ...state,
            level: level.level,
            levelId: level.levelId,
            repCode: level.repCode,
            backColorCode: level.backColorCode,
            foreColorCode: level.foreColorCode,
            value: level.value,
          }));
        })
        .catch(() => {
          /* ... */
        });
    }
  };
  const handleAddSkillInterested = (params, skill, setState) => {
    if (
      skill &&
      hasPermission(userRolesWithPermission, "CROSS_SKILLING.ACCEPT_INTEREST")
    ) {
      const defaultSkillLevel = getLowestLevelObject(allSkillLevels);
      const interestedSkill = params.row.skillInterests
        ? params.row.skillInterests.find(
            (x) => parseInt(x.skillId) === parseInt(skill.skillId)
          )
        : false;
      confirm({
        title: "Are you sure?",
        description: `You are adding ${skill.name} to ${params.row.firstName}'s skill set.`,
      })
        .then(() => {
          dispatch(addSkillInterested(interestedSkill.id));
          setState((state) => ({
            ...state,
            level: defaultSkillLevel.level,
            levelId: defaultSkillLevel.levelId,
            repCode: defaultSkillLevel.repCode,
            backColorCode: defaultSkillLevel.backColorCode,
            foreColorCode: defaultSkillLevel.foreColorCode,
            value: defaultSkillLevel.value,
            isInterest: false,
            skillAssigned: true,
          }));
        })
        .catch(() => {
          /* ... */
        });
    }
  };

  const getFilter = useCallback(
    (type) => {
      let returnValue = {
        type: type,
      };
      returnValue[`${type}s`] = [];
      filters.forEach((filter) => {
        if (type === filter.type) {
          returnValue = filter;
        }
      });
      return returnValue;
    },
    [filters]
  );

  const { columns } = useCrossSkillColumn(
    allOrganizations,
    filteredJobs,
    allSkills,
    allSkillLevels,
    filters,
    handleSkillLevels,
    handleAddSkillInterested,
    searchText,
    getFilter,
    allOrganizationTypes,
    fetchAllOrganizationsStatus
  );
  let { defaultColumns, restColumns } = useColumnDivider(
    columns,
    fixedColumns,
    defaultCheckedColumns
  );
  let [{ selectedColumns, pickerColumns }, { handleSelectColumns }] =
    useColumnSelector(defaultColumns, restColumns);
  useEffect(() => {
    const newFinalPickerColumn = pickerColumns.filter((pickerColumn) =>
      fixedColumns.includes(pickerColumn.field)
    );
    setFinalPickerColumn(newFinalPickerColumn);
  }, [fixedColumns, pickerColumns]);

  useEffect(() => {
    try {
      if (allWorkCenters.length > 0 && skillMatrixAppliedFilters.workcenters) {
        const newSelectedWorkCenters = [];
        allWorkCenters.forEach((wc) => {
          const findWc = skillMatrixAppliedFilters.workcenters
            .split(",")
            .find((wcId) => parseInt(wcId) === wc.id);
          if (findWc) {
            newSelectedWorkCenters.push(wc.id);
          }
        });
        setSelectedWorkCenter(newSelectedWorkCenters);
      } else {
        setSelectedWorkCenter([]);
      }
    } catch (e) {
      // Do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillMatrixAppliedFilters]);

  useEffect(() => {
    setDataProcessed(false);
    dispatch(fetchSkillMatrixDefaultFilters());
    dispatch(fetchSkillMatrixFilters()); // Dedicated Skill Matrix Filter API
    dispatch(fetchNotAssignedSkillLevel());
  }, [dispatch]);

  useEffect(() => {
    if (
      skillMatrixDefaultFiltersStatus === "fulfilled" &&
      skillMatrixFiltersStatus === "fulfilled"
    ) {
      dispatch(resetSkillMatrixDefaultFilters());
      dispatch(resetSkillMatrixFilter());
      dispatch(fetchManageWorkersData({ getAll: true, version: 1 }));
    }
  }, [
    dispatch,
    skillMatrixDefaultFiltersStatus,
    skillMatrixAppliedFilters,
    skillMatrixFiltersStatus,
  ]);

  useEffect(() => {
    if (fetchManageWorkersDataStatus === "fulfilled") {
      try {
        if (
          allLocations &&
          allLocations.length > 0 &&
          (allWorkers.length > 0 ||
            fetchManageWorkersDataStatus === "fulfilled") &&
          (allJobRoles || skillMatrixFiltersStatus === "fulfilled") &&
          (allSkills.length > 0 || fetchJobsByUserIdStatus === "fulfilled") &&
          (allSkillLevels.length > 0 ||
            skillMatrixFiltersStatus === "fulfilled")
        ) {
          const getSkillName = (skillId) => {
            try {
              const skill = allSkills.find(
                (x) => parseInt(x.id) === parseInt(skillId)
              );
              return skill.name;
            } catch (e) {
              return "";
            }
          };
          const getJobNames = (jobIds) => {
            try {
              let returnValue = "";
              const jobIdsArray = jobIds ? jobIds.split(",") : [];
              jobIdsArray.forEach((jobId) => {
                if (allJobRoles[jobId]) {
                  returnValue += allJobRoles[jobId].name;
                }
              });
              return returnValue;
            } catch (e) {
              return "";
            }
          };
          const getWorkCenterIds = (locationId) => {
            let returnValue = "";
            let returnValueArray = [];
            const selectedLocation = allLocations.find(
              (x) => x.id === parseInt(locationId)
            );
            if (selectedLocation) {
              const workcentresList = allWorkCenters
                ? allWorkCenters.filter(
                    (wc) => wc.locationId === selectedLocation.id
                    // eslint-disable-next-line no-mixed-spaces-and-tabs
                  )
                : [];
              workcentresList.forEach((wc) => {
                returnValueArray.push(wc.id);
              });
            }
            returnValue = returnValueArray.toString();
            return returnValue;
          };

          const getWorkCenterName = (workCenterId) => {
            let returnValue = "-";
            const workCenter = allWorkCenters.find(
              (x) => x.id === parseInt(workCenterId)
            );
            if (workCenter) {
              returnValue = workCenter.name;
            }
            return returnValue;
          };

          const getManagerName = (managerId) => {
            let returnValue = "-";
            const manager = allManagers.find(
              (x) => x.id === parseInt(managerId)
            );
            if (manager) {
              returnValue = manager.name;
            }
            return returnValue;
          };

          const getShiftPatternName = (shiftPatternId) => {
            let returnValue = "-";
            const shiftPattern = allShiftPatterns.find(
              (x) => x.id === parseInt(shiftPatternId)
            );
            if (shiftPattern) {
              returnValue = shiftPattern.name;
            }
            return returnValue;
          };

          // Default experience level
          let defaultExperienceLevel = getLowestLevelObject(allSkillLevels);
          // Assign default 0 level to default experience level
          if (allSkillLevels.length > 0) {
            allSkillLevels.forEach((level) => {
              if (level === 0) {
                defaultExperienceLevel = {
                  levelId: level.id,
                  level: level.level,
                  levelName: level.name,
                  skillAssigned: false,
                  backColorCode: level.backColorCode,
                  desc: level.desc,
                  foreColorCode: level.foreColorCode,
                  icon: level.icon,
                  repName: level.repName,
                  repCode: level.repCode,
                  value: level.value,
                };
              }
            });
          }

          let tempData = [];

          // Loop through all workers data
          allWorkers.forEach((worker) => {
            let tempWorker = {
              ...worker,
              skills: [],
              workCenterIds: getWorkCenterIds(worker.locationId),
              workCenter: getWorkCenterName(worker.workcenterId),
              managerName: getManagerName(worker.managerId),
              shiftPatternName: getShiftPatternName(worker.shiftPatternId),
              companyEmployeeId: Object.prototype.hasOwnProperty.call(
                worker,
                "companyEmployeeId"
              )
                ? worker.companyEmployeeId !== ""
                  ? worker.companyEmployeeId ?? "-"
                  : "-"
                : "-",
              jobNames: getJobNames(worker.jobIds),
              jobTitle: Object.prototype.hasOwnProperty.call(worker, "jobTitle")
                ? worker.jobTitle !== ""
                  ? worker.jobTitle ?? "-"
                  : "-"
                : "-",
            };

            let tempSkills = [...allSkills];
            let skillInterests = [...worker.skillInterests];
            let jobIds = worker?.jobIds?.split(",");
            // Getting job ids from all jobs
            let allJobIds = Object.keys(allJobRoles).reduce((acc, key) => {
              acc.push(key);
              return acc;
            }, []);

            // Removing worker's jobs from all job ids
            allJobIds = allJobIds.filter((v) => !jobIds?.includes(v));

            // Inject job Name in worker existing skills and job
            worker.skills.forEach((workerSkill) => {
              let foundInJob = false;
              let levelDetails = allSkillLevels.find(
                (level) => level.id === workerSkill.levelId
              );
              if (!levelDetails) {
                levelDetails = defaultExperienceLevel;
              }
              jobIds?.forEach((jobId) => {
                let job = allJobRoles[`${jobId}`];
                if (job) {
                  job.skills.forEach((jobSkill) => {
                    if (
                      parseInt(jobSkill.skillId) ===
                      parseInt(workerSkill.skillId)
                    ) {
                      foundInJob = true;
                      tempWorker.skills.push({
                        ...workerSkill,
                        skillId: workerSkill.skillId,
                        skillName: getSkillName(workerSkill.skillId),
                        jobName: job.name,
                        jobId: job.id,
                        jobAssigned: true,
                        skillAssigned: true,
                        interested: false,
                        backColorCode: levelDetails.backColorCode,
                        desc: levelDetails.desc,
                        foreColorCode: levelDetails.foreColorCode,
                        icon: levelDetails.icon,
                        repName: levelDetails.repName,
                        repCode: levelDetails.repCode,
                        value: levelDetails.value,
                        levelName: levelDetails.name,
                        level: levelDetails.level,
                      });
                    }
                  });
                }
              });
              // Update skill with details
              if (!foundInJob) {
                let tempJobId = tempWorker.skills.length;
                tempWorker.skills.push({
                  ...workerSkill,
                  skillId: workerSkill.skillId,
                  skillName: getSkillName(workerSkill.skillId),
                  jobName: "",
                  jobId: `generated-id-${tempJobId}`,
                  jobAssigned: true,
                  skillAssigned: true,
                  interested: false,
                  backColorCode: levelDetails.backColorCode,
                  desc: levelDetails.desc,
                  foreColorCode: levelDetails.foreColorCode,
                  icon: levelDetails.icon,
                  repName: levelDetails.repName,
                  repCode: levelDetails.repCode,
                  value: levelDetails.value,
                  levelName: levelDetails.name,
                  level: levelDetails.level,
                });
              }
            });

            // Loop through all skills and jobs
            if (tempSkills.length > 0) {
              tempSkills.forEach((tSkill) => {
                let tempJobId = tempWorker.skills.length;
                let foundInAnyJob = false;
                allJobIds.forEach((id) => {
                  let job = allJobRoles[`${id}`];
                  for (let i = 0; i < job.skills.length; i++) {
                    let jSkill = job.skills[i];
                    if (parseInt(jSkill.skillId) === parseInt(tSkill.id)) {
                      foundInAnyJob = true;
                      let foundInExistingSkill = false;
                      // using default skill level before modifying based on existing level
                      let existingSkillLevel = { ...defaultExperienceLevel };
                      // Check worker skills to copy level and stop duplicate entries
                      tempWorker.skills.forEach((wSkill) => {
                        if (
                          parseInt(wSkill.skillId) === parseInt(jSkill.skillId)
                        ) {
                          const levelDetails =
                            allSkillLevels.find(
                              (level) => level.id === wSkill.levelId
                            ) ?? existingSkillLevel;
                          if (wSkill.jobId.toString() === job.id.toString()) {
                            foundInExistingSkill = true;
                          }
                          existingSkillLevel = {
                            levelId: wSkill.levelId,
                            level: levelDetails.level,
                            levelName: levelDetails.name,
                            backColorCode: levelDetails.backColorCode,
                            desc: levelDetails.desc,
                            foreColorCode: levelDetails.foreColorCode,
                            icon: levelDetails.icon,
                            repName: levelDetails.repName,
                            repCode: levelDetails.repCode,
                            value: levelDetails.value,
                          };
                        }
                      });
                      // Copy skill level if exists or use default
                      if (!foundInExistingSkill) {
                        let interested = false;
                        skillInterests.forEach((iSkill) => {
                          if (
                            parseInt(iSkill.skillId) ===
                            parseInt(jSkill.skillId)
                          ) {
                            interested = true;
                          }
                        });
                        if (interested) {
                          tempWorker.skills.push({
                            ...tSkill,
                            jobName: job.name,
                            jobId: job.id,
                            skillName: tSkill.name,
                            skillId: tSkill.id,
                            levelId: existingSkillLevel.levelId,
                            level: existingSkillLevel.level,
                            levelName: existingSkillLevel.name,
                            jobAssigned: false,
                            skillAssigned: false,
                            interested,
                            backColorCode: existingSkillLevel.backColorCode,
                            desc: existingSkillLevel.desc,
                            foreColorCode: existingSkillLevel.foreColorCode,
                            icon: existingSkillLevel.icon,
                            repName: existingSkillLevel.repName,
                            repCode: existingSkillLevel.repCode,
                            value: existingSkillLevel.value,
                          });
                        }
                      }
                      break;
                    }
                  }
                });
                //If skill is not found in any job
                if (!foundInAnyJob) {
                  let interested = false;
                  skillInterests.forEach((iSkill) => {
                    if (parseInt(iSkill.skillId) === parseInt(tSkill.id)) {
                      interested = true;
                    }
                  });
                  // Get skill level if exists in worker skills
                  let existingSkillLevel = { ...defaultExperienceLevel };
                  tempWorker.skills.forEach((eSkill) => {
                    if (parseInt(eSkill.skillId) === parseInt(tSkill.id)) {
                      const levelDetails =
                        allSkillLevels.find(
                          (level) => level.id === eSkill.levelId
                        ) ?? defaultExperienceLevel;
                      existingSkillLevel = {
                        levelId: levelDetails.id,
                        level: levelDetails.level,
                        levelName: levelDetails.name,
                        //skill assigned otherskills
                        skillAssigned: true,
                        backColorCode: levelDetails.backColorCode,
                        desc: levelDetails.desc,
                        foreColorCode: levelDetails.foreColorCode,
                        icon: levelDetails.icon,
                        repName: levelDetails.repName,
                        repCode: levelDetails.repCode,
                        value: levelDetails.value,
                      };
                    }
                  });
                  const newSkill = {
                    ...tSkill,
                    jobName: "",
                    jobId: `generated-id-${tempJobId}`,
                    skillName: tSkill.name,
                    skillId: tSkill.id,
                    levelId: existingSkillLevel.levelId,
                    level: existingSkillLevel.level,
                    levelName: existingSkillLevel.levelName,
                    jobAssigned: false,
                    //skill assigned otherskills
                    skillAssigned: existingSkillLevel.skillAssigned,
                    interested,
                    backColorCode: existingSkillLevel.backColorCode,
                    desc: existingSkillLevel.desc,
                    foreColorCode: existingSkillLevel.foreColorCode,
                    icon: existingSkillLevel.icon,
                    repName: existingSkillLevel.repName,
                    repCode: existingSkillLevel.repCode,
                    value: existingSkillLevel.value,
                  };
                  if (interested) {
                    tempWorker.skills.push(newSkill);
                  }
                }
              });
            }
            tempWorker.skills = Array.isArray(tempWorker.skills)
              ? tempWorker.skills.filter(
                  (v, i, a) =>
                    a.findIndex((v2) => v2.skillId === v.skillId) === i
                )
              : [];
            tempData.push(tempWorker);
          });

          // Remove duplicate workers
          tempData = tempData.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          setAllData(tempData);

          setFixedColumns([
            "companyEmployeeId",
            "jobTitle",
            "name",
            "managerName",
            "workCenter",
            "shiftPatternName",
            "skillScore",
          ]);
          setDataProcessed(true);
        }
      } catch (e) {
        // Do nothing
        setDataProcessed(true);
      }
    }
  }, [
    allJobRoles,
    allLocations,
    allSkillLevels,
    allSkills,
    allWorkCenters,
    allWorkers,
    allManagers,
    fetchJobsByUserIdStatus,
    fetchManageWorkersDataStatus,
    skillMatrixFiltersStatus,
    allShiftPatterns,
  ]);

  useEffect(() => {
    try {
      let newTempData = [];
      allData.forEach((worker) => {
        let tempSkills = [...worker.skills];
        // Remove duplicate skills
        tempSkills = tempSkills.filter(
          (v, i, a) =>
            a.findIndex(
              (v2) => parseInt(v2.skillId) === parseInt(v.skillId)
            ) === i
        );
        // Remove unassigned skills
        tempSkills = tempSkills.filter((skill) => skill.skillAssigned);

        let newWorkerObj = {
          ...worker,
          skills: tempSkills,
        };
        newTempData.push(newWorkerObj);
      });
      if (searchText === "") setData(newTempData);
      else {
        let filteredData = newTempData.filter((worker) => {
          const fullName = `${worker["firstName"]} ${worker["lastName"]}`;
          const companyEmployeeId = worker.companyEmployeeId
            ? worker.companyEmployeeId
                .toLowerCase()
                .includes(searchText.toLowerCase())
            : false;
          const managerName = worker.managerName
            ? worker.managerName
                .toLowerCase()
                .includes(searchText.toLowerCase())
            : false;
          const workerSkills = worker.skills.some((skill) =>
            skill.skillName.toLowerCase().includes(searchText.toLowerCase())
          );
          const workerJobs = worker.jobNames
            ? worker.jobNames.toLowerCase().includes(searchText.toLowerCase())
            : false;
          const workerJobTitle = worker.jobTitle
            ? worker.jobTitle.toLowerCase().includes(searchText.toLowerCase())
            : false;
          const workerWorkCenter = worker.workCenter
            ? worker.workCenter.toLowerCase().includes(searchText.toLowerCase())
            : false;
          const shiftPattern = worker.shiftPatternName
            ? worker.shiftPatternName
                .toLowerCase()
                .includes(searchText.toLowerCase())
            : false;
          return (
            fullName.toLowerCase().includes(searchText.toLowerCase()) ||
            companyEmployeeId ||
            managerName ||
            workerSkills ||
            workerJobs ||
            workerJobTitle ||
            workerWorkCenter ||
            shiftPattern
          );
        });
        setData(filteredData);
      }
    } catch (e) {
      console.log("Error", e);
    }
  }, [searchText, allData]);

  return [
    {
      data,
      allData,
      filters,
      allJobRoles,
      allJobs,
      allManagers,
      allSkills,
      allSkillLevels,
      allWorkCenters,
      allShiftPatterns,
      allWorkers,
      allOrganizations,
      allOrganizationTypes,
      allLocations,
      workerTypes,
      filterStatus,
      selectedWorkCenter,
      updateWorkerSkillLevelStatus,
      fullTimeSchedule,
      width,
      dataProcessed,
      fetchManageWorkersDataStatus,
      fetchAllLocationsStatus,
      fetchSkillLevelsStatus,
      fetchAllOrganizationsStatus,
      fetchAllManagersStatus,
      fetchJobsByUserIdStatus,
      gotOrgs,
      searchText,
      containerRef,
      filterControllerProps,
      filterControllerEvents,
      filteredJobs,
      selectedColumns,
      columns,
      finalPickerColumn,
      skillMatrixFiltersStatus,
      skillMatrixAppliedFilters,
      totalWorkers,
      pageSize,
      selectedPage,
      skillInsightsEnabled,
      isSkillPriorityEnabled,
      notAssignedSkillLevel,
    },
    {
      setFilters,
      setFilterStatus,
      setSelectedWorkCenter,
      resetSkillLevelStatus,
      dispatch,
      handleSkillLevels,
      handleAddSkillInterested,
      handleSelectColumns,
      setSearchText,
      getFilter,
      setSelectedPage,
      setPageSize,
    },
  ];
};

export default useCrossSkillController;
