import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
	createNewLocation,
	getLocations,
} from "../../../../../../../export/gat-admin";
import { COUNTRIES } from "../../../../../../../ts/components/gat-admin/constants/countries";
import useGatAdminConfigData from "../../../../../../../ts/components/gat-admin/controllers/use-gat-admin-config-data";
import { updateLocationData } from "../../../../../../../ts/components/gat-admin/store/gat-admin-slice";
import isValidPhoneNumberForCountry from "../../../../../../utils/helpers";
import { findDifferentKeys } from "../../../../utils";

const defaultAllocationSettings = [
	{
		id: 0,
		name: "Fastest Acceptance",
		code: "FASTEST",
		isDefault: true,
		isConfigurable: false,
		isActive: true,
		shiftAllocationTypeId: 1,
		urgentWindowSizeMins: 0,
		defaultWindowSizeMins: 0,
		tieBreakerProp: "rscore",
		jobIds: null,
	},
	{
		id: 0,
		name: "Seniority Based Allocation",
		code: "SENIORITY",
		isDefault: false,
		isConfigurable: true,
		isActive: true,
		shiftAllocationTypeId: 2,
		urgentWindowSizeMins: 0,
		defaultWindowSizeMins: 0,
		tieBreakerProp: "rscore",
		jobIds: null,
	},
	{
		id: 0,
		name: "Workcenter Based",
		code: "WORKCENTER",
		isDefault: false,
		isConfigurable: true,
		isActive: true,
		shiftAllocationTypeId: 3,
		urgentWindowSizeMins: 0,
		defaultWindowSizeMins: 0,
		tieBreakerProp: "rscore",
		jobIds: null,
	},
	{
		id: 0,
		name: "Worker Type and Location based",
		code: "LOCATION",
		isDefault: false,
		isConfigurable: true,
		isActive: true,
		shiftAllocationTypeId: 4,
		urgentWindowSizeMins: 0,
		defaultWindowSizeMins: 0,
		tieBreakerProp: "rscore",
		jobIds: null,
	},
];

const initialLocationData = {
	company: "",
	buildingNumber: "",
	address1: "",
	city: "",
	state: "",
	zip: "",
	county: "",
	country: "",
	timeZone: "",
	contactForWorkers: "",
	name: "",
	isActive: true,
	companyContactForWorker: "",
};

const useEssentialLocationController = (
	locationData: any,
	creatingNewLocation: boolean
) => {
	const dispatch = useDispatch();

	const {
		timeZones,
		updateLocationDataStatus,
		createNewLocationStatus,
		allocationTypes,
	} = useGatAdminConfigData();

	const [loading, setLoading] = useState(false);

	const [pocError, setPocError] = useState("");
	const [selectedCountry, setSelectedCountry] = useState(COUNTRIES[0]);
	const [countryInputValue, setCountryInputValue] = useState(COUNTRIES[0].name);

	const [essentialLocationData, setEssentialLocationData] =
		useState(initialLocationData);
	const [newEssentialLocationData, setNewEssentialLocationData] =
		useState(initialLocationData);

	useEffect(() => {
		if (!creatingNewLocation && locationData.id) {
			const obj = {
				id: locationData.id,
				companyId: locationData.companyId,
				company: locationData.company,
				buildingNumber: locationData.buildingNumber,
				address1: locationData.address1,
				city: locationData.city,
				state: locationData.state,
				zip: locationData.zip,
				county: locationData.county,
				country: locationData.country,
				timeZone: locationData.timeZone,
				contactForWorkers: locationData.contactForWorkers,
				companyContactForWorker: locationData.contactForWorkers,
				name: locationData.name,
				isActive: locationData.isActive,
			};
			setEssentialLocationData(obj);
			setNewEssentialLocationData(obj);
		}
	}, [locationData, creatingNewLocation]);

	useEffect(() => {
		if (locationData.companyId && updateLocationDataStatus === "fulfilled") {
			dispatch(getLocations(locationData.companyId));
		}
	}, [locationData.companyId, updateLocationDataStatus, dispatch]);

	const handleLocationSave = () => {
		setLoading(true);
		const changedCompany: string[] = findDifferentKeys(
			essentialLocationData,
			newEssentialLocationData
		);
		if (changedCompany.length > 0 && !creatingNewLocation) {
			dispatch(updateLocationData(newEssentialLocationData));
		} else if (creatingNewLocation) {
			dispatch(
				createNewLocation({
					...newEssentialLocationData,
					companyId: locationData.companyId,
					allocationSettings: defaultAllocationSettings,
				})
			);
		}
	};

	const validateContacts = (
		contactsString: string,
		country: { iso2: string; name: string }
	) => {
		try {
			const contacts = contactsString.split(",");
			let isValid = true;
			for (let i = 0; i < contacts.length; i++) {
				const phoneNumber = contacts[i].trim();
				if (phoneNumber === "") {
					break;
				}
				if (phoneNumber.length > 1) {
					if (
						!isValidPhoneNumberForCountry(
							phoneNumber,
							country.iso2.toUpperCase()
						)
					) {
						isValid = false;
						break;
					}
				} else {
					isValid = false;
					break;
				}
			}
			if (isValid) {
				setPocError("");
			} else {
				setPocError(
					`Please enter valid phone numbers with country code for ${country.name}.`
				);
			}
		} catch (e) {
			// Do nothing
		}
	};

	useEffect(() => {
		if (
			createNewLocationStatus === "fulfilled" ||
			updateLocationDataStatus === "fulfilled"
		) {
			setLoading(false);
			setEssentialLocationData(newEssentialLocationData);
		}
	}, [
		createNewLocationStatus,
		updateLocationDataStatus,
		newEssentialLocationData,
		dispatch,
	]);

	return [
		{
			essentialLocationData,
			newEssentialLocationData,
			timeZones,
			pocError,
			selectedCountry,
			countryInputValue,
			updateLocationDataStatus,
			loading,
			createNewLocationStatus,
		},
		{
			setEssentialLocationData,
			setNewEssentialLocationData,
			validateContacts,
			setPocError,
			setSelectedCountry,
			setCountryInputValue,
			handleLocationSave,
			setLoading,
		},
	];
};

export default useEssentialLocationController;
