import { useSelector } from "react-redux";

import {
	SkillInsightRootState,
	WorkerInsightRootState,
} from "../slices/insight-report-types";

const useSkillInsightReportData = () => {
	const skillsReportData = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.skillInsightReportData
	);
	const fetchSkillInsightReportStatus = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.fetchSkillInsightReport.status
	);

	const skillInsightDetailsStatus = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.fetchSkillInsightDetails?.status
	);

	const skillsInsightData = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.skillInsightDetailsData
	);

	const allLocations = useSelector(
		(state: any) => state.manageWorkers.userLocations
	);
	const allSkillLevels = useSelector(
		(state: WorkerInsightRootState) =>
			state.workerInsightReportData.skillsLevelLegendsData
	);
	const isSkillPriorityEnabled = useSelector(
		(state: any) => state.user.userData.skillPriorityEnabled
	);

	const allPriorities = useSelector(
		(state: any) => state.skills.skillPriorities
	);
	const selectedKPI = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.skillInsightPanelDetails.selectedKPI
	);

	return {
		skillsReportData,
		fetchSkillInsightReportStatus,
		allLocations,
		allSkillLevels,
		isSkillPriorityEnabled,
		allPriorities,
		skillInsightDetailsStatus,
		skillsInsightData,
		selectedKPI,
	};
};
export default useSkillInsightReportData;
