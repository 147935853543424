import Box from "@mui/material/Box";
import React from "react";

import AbbreviationSkillRepresentation from "../../../../../design/skill-representaion-cell/ui/abbreviation-skill-representation";
import BoxSkillRepresentation from "../../../../../design/skill-representaion-cell/ui/box-skill-representation";
import CircleSkillRepresentation from "../../../../../design/skill-representaion-cell/ui/circle-skill-representation";
import SkillDonutRepresentation from "../../../../../design/skill-representaion-cell/ui/donut-skill-representation";
import {
    ALPHABET,
    BOX,
    CIRCLE,
    DONUT,
    NUMBER,
} from "../../../constants";

interface SkillRepresentationCellProps {
    representationType?: string;
    representationLevel?: number | string;
    level?: number;
    value?: string | number;
    backgroundColor?: string;
    height?: number;
    width?: number;
    backgroundHeight?: string;
    backgroundWidth?: string;
    border?: number;
    foregroundColor?: string;
    inSelect?: boolean;
}

const SkillRepresentationCell: React.FC<SkillRepresentationCellProps> = ({
                                                                             representationType = DONUT,
                                                                             representationLevel,
                                                                             level = 0,
                                                                             value = "",
                                                                             backgroundColor = "",
                                                                             height = 20,
                                                                             width = 20,
                                                                             backgroundHeight = "35px",
                                                                             backgroundWidth = "35px",
                                                                             border = 0,
                                                                             foregroundColor = "rgba(0,0,0,0.87)",
                                                                             inSelect = false, // To handle weird select css behavior
                                                                         }) => {
    let showBorder = false;
    let repLevel: number | string;
    if (!representationLevel) {
        repLevel = level;
    } else {
        repLevel = representationLevel;
    }

    let ReturnSkillRepresentationCell = (
        <SkillDonutRepresentation
            representationLevel={repLevel}
            size={width}
        />
    );

    switch (representationType) {
        case ALPHABET:
        case NUMBER:
            showBorder = backgroundColor === "" || backgroundColor === null;
            ReturnSkillRepresentationCell = (
                <AbbreviationSkillRepresentation
                    text={value}
                    foregroundColor={foregroundColor}
                    size={width}
                    inSelect={inSelect}
                />
            );
            break;
        case BOX:
            ReturnSkillRepresentationCell = (
                <BoxSkillRepresentation representationLevel={repLevel} size={width} />
            );
            break;
        case CIRCLE:
            ReturnSkillRepresentationCell = (
                <CircleSkillRepresentation representationLevel={repLevel} size={width} />
            );
            break;
        default:
            break;
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                    backgroundColor === null || backgroundColor === ""
                        ? "rgba(0,0,0,0)"
                        : `#${backgroundColor}`,
                width: backgroundWidth,
                height: backgroundHeight,
                borderRadius: backgroundWidth !== "100%" ? "3px" : 0,
                border: showBorder ? border : 0,
            }}
        >
            {ReturnSkillRepresentationCell}
        </Box>
    );
}

export default SkillRepresentationCell;
