/* eslint-disable simple-import-sort/imports */
import React, {useEffect} from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Box from "@mui/material/Box";
import { Accordion, Tooltip, Typography, styled } from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps, } from "@mui/material/AccordionSummary";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CrossSkillLegends from "../../../../cross-skilling/ui/cross-skill-header/ui/cross-skill-ledgends";
import Loading from "../../../../../../js/components/Loading";
import Highlight from "../../../../Highlight";
import Searchbar from "../../../../../design/search-bar";
import useTableHeight from "../../../../../utils/use-table-height";
import useInsightSkillDetailsByLevelController from "../../../controllers/use-insight-skill-details-levels-controller";
import PersonOffIcon from "@mui/icons-material/PersonOff";

function SKillInsightDetailsWorkerTab({ allSkillLevels, fetchSkillInsightDetailsStatus, allLocations }: any) {

    const [
        {
            searchText,
            searchSkillInsightDetailsData,
            expanded
        },
        {
            setSearchText,
            setExpanded
        }
    ]: any = useInsightSkillDetailsByLevelController();

    useEffect(() => {
        setSearchText("");
    }, [setSearchText, fetchSkillInsightDetailsStatus]);


    const { height, boxRef } = useTableHeight();

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
    ))(({ theme }) => ({
        "&.Mui-expanded": {
            minHeight: 0,
            marginTop: 10,
            paddingRight: 0,
        },
        backgroundColor: "transparent",
        height: 30,
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
        },

        paddingLeft: 10,
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        paddingLeft: 10,
        paddingRight: 10,
        width: "100%"
    }));

    const StyledAccordion = styled(Accordion)(({ theme }) => ({
        borderRadius: 0,
        boxShadow: "none",
    }));

    const columns: any = [
        {
            field: "workerName",
            headerName: "Worker Name",
            flex: 1,
            renderCell: (params: any) => {
                const fullName = `${params?.row?.workerName}`;
                return (
                    <Tooltip title={fullName}>
                        <span
                            style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                marginLeft: "12px",
                            }}>
                            <Highlight searchText={searchText} value={fullName} />
                        </span>
                    </Tooltip>
                );
            }
        },
        { field: "location", headerName: "Worker Location", flex: 1 },
        { field: "workcenter", headerName: "Workcenter", flex: 1, align:"center", headerAlign:"center" },
    ];

    const onHandleAccordian = (level: string) => {
        setExpanded((prevExpanded: any) => {
            if (prevExpanded.includes(level)) {
                return prevExpanded.filter((accordionName: any) => accordionName !== level);
            } else {
                return [...prevExpanded, level];
            }
        });
    };

    const displayWorkersBySkillLevel = () => {

        const workersBySkillLevels: any = {}

        if (allSkillLevels !== undefined && allSkillLevels.length > 0) {

            allSkillLevels?.map((skillLevel: any) => {
                workersBySkillLevels[skillLevel.level] = []
            })

            searchSkillInsightDetailsData?.workersList?.map((worker: any, index: number) => {
                if (allLocations.filter((location: any) => location.id === worker.locationId).length !== 0) {
                    workersBySkillLevels[worker.level].push({
                        id: index,
                        workerName: worker.firstName + " " + worker.lastName,
                        location: worker.location,
                        workcenter: worker.workcenterName === "" ? "-" : worker.workcenterName
                    })
                }

            })
        }

        return (

            Object.keys(workersBySkillLevels).map((level: any) => (

                workersBySkillLevels[level].length > 0 ?

                    <Box key={level} style={{ fontWeight: "bold" }} >

                        <StyledAccordion expanded={expanded.includes(level) || searchText.length > 0} onChange={() => onHandleAccordian(`${level}`)}>

                            <AccordionSummary aria-controls={`${level}d-content`} id={`${level}-header`}>
                                <Box sx={{ display: "flex", alignItems: "center", height: 10 }}>
                                    <CrossSkillLegends isSkillLevelShownInTableHeader={true} allSkillLevels={[allSkillLevels[level]]} />
                                    <Typography style={{ fontSize: "14px" }} ml={1}>{`(${workersBySkillLevels[level].length})`}</Typography>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails >
                                {displayWorkersBySkillLevelTable(workersBySkillLevels[level])}
                            </AccordionDetails>

                        </StyledAccordion>
                    </Box>
                    : null
            ))
        )
    }

    const displayInterestedWorkers = () => {

        const rows: Array<any> = []

        let interestedWorkersLength = 0
        searchSkillInsightDetailsData?.interestedWorkersList?.map((interestedWorker: any, index: number) => {
            interestedWorkersLength = allLocations.filter((location: any) => location.id === interestedWorker.locationId).length

            if (interestedWorkersLength !== 0) {
                rows.push({ id: index, workerName: interestedWorker.firstName + " " + interestedWorker.lastName, location: interestedWorker.location, workcenter: interestedWorker.workcenterName === "" ? "-" : interestedWorker.workcenterName})
            }
        })

        return (rows.length !== 0 &&

            <Box key={"interestedWorker"}  >
                <StyledAccordion expanded={expanded.includes("interested worker") || searchText.length > 0} onChange={() => onHandleAccordian("interested worker")}>

                    <AccordionSummary aria-controls={"interestedWorkerd-content"} id={"interestedWorker-header"}>
                        <Box sx={{ display: "flex", alignItems: "center", height: 10 }}>
                            <Typography ml={2} style={{ font: "0.9rem", fontWeight: "500" }}>Interested Workers</Typography>
                            <Typography style={{ fontSize: "14px" }} ml={1}>{`(${rows.length})`}</Typography>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails style={{ display: "grid" }}>
                        {displayWorkersBySkillLevelTable(rows)}
                    </AccordionDetails>

                </StyledAccordion>
            </Box>)
    }

    const displayWorkersBySkillLevelTable = (rows: any) => {

        return (
            <Box ref={boxRef} sx={{ width: "100%" }} >

                <DataGridPro
                    rows={rows}
                    columns={columns}
                    disableColumnFilter
                    disableColumnMenu
                    pagination={rows.length > 5}
                    disableColumnSelector
                    disableDensitySelector
                    autoPageSize={true}
                    pageSize={5} //5 - Max number of rows
                    autoHeight={true}
                    sx={{
                        "& .MuiDataGrid-root": {
                        },
                        "& .MuiDataGrid-row": {
                            cursor: "pointer",
                        },
                        "& .MuiDataGrid-cell": {
                        },
                        "& .MuiDataGrid-columnHeader, & .MuiDataGrid-columnHeaderWrapper": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)"
                        },
                        "& .MuiDataGrid-columnHeader:last-of-type": {
                            borderRight: "none",
                        },
                        "& .MuiDataGrid-cell:last-of-type": {
                            borderRight: "none",
                        },
                        overflow: "hidden",
                        border: "none !important",
                    }}
                />
            </Box>
        )
    }

    return (

        fetchSkillInsightDetailsStatus !== "pending" ?

            <Box >
                {(searchSkillInsightDetailsData?.interestedWorkersList?.length > 0 || searchSkillInsightDetailsData?.workersList?.length > 0 || searchText.length > 0) &&

                    <Box sx={{ marginRight: 2 }}>
                        <Searchbar
                            placeholder={"Worker Name"}
                            setSearch={(value: any) => setSearchText(value.replace(/[^a-zA-Z0-9 ]/g, ""))}
                            value={searchText}
                            debounce
                        />
                    </Box>
                }
                {(searchSkillInsightDetailsData?.interestedWorkersList?.length > 0 || searchSkillInsightDetailsData?.workersList?.length > 0) ?
                    <>
                        {displayWorkersBySkillLevel()}

                        {displayInterestedWorkers()}
                    </>
                    :
                    <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column", mt: 10 }}>
                        <PersonOffIcon sx={{ color: "#6c6d6e", fontSize: "40px" }} />
                        No workers {searchText.length > 0 ? "found" : "available"}
                    </Box>
                }
            </Box>
            : <Loading />
    )
}

export default SKillInsightDetailsWorkerTab