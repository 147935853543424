import Box from "@mui/material/Box";
import React from "react";

import { ALPHABET, BOX, CIRCLE, DONUT, NUMBER } from "./constants/constants";
import AbbreviationSkillRepresentation from "./ui/abbreviation-skill-representation";
import BoxSkillRepresentation from "./ui/box-skill-representation";
import CircleSkillRepresentation from "./ui/circle-skill-representation";
import SkillDonutRepresentation from "./ui/donut-skill-representation";

type SkillRepresentationCellProps = {
	representationType?: string;
	representationLevel?: string;
	level?: number;
	value?: number | string;
	backgroundColor?: string;
	height?: number | string;
	width?: number | string;
	backgroundHeight?: number | string;
	backgroundWidth?: number | string;
	border?: number;
	foregroundColor?: string;
	inSelect?: boolean;
};

function SkillRepresentationCell({
	representationType,
	representationLevel,
	level,
	value,
	backgroundColor,
	height,
	width,
	backgroundHeight,
	backgroundWidth,
	border,
	foregroundColor,
	inSelect,
}: SkillRepresentationCellProps) {
	// CHANGE to use in skill chip in select skills showBorder
	let showBorder = false;
	let repLevel;
	let size = 20;

	if (typeof width === "string") {
		size = parseInt(width, 10);
	} else if (typeof width === "number") {
		size = width;
	}
	if (!representationLevel) {
		repLevel = level;
	} else {
		repLevel = representationLevel;
	}
	let ReturnSkillRepresentationCell = (
		<SkillDonutRepresentation
			representationLevel={repLevel}
			size={size}
		/>
	);
	switch (representationType) {
		case ALPHABET:
		case NUMBER:
			showBorder = backgroundColor === "" || backgroundColor === null;

			ReturnSkillRepresentationCell = (
				<AbbreviationSkillRepresentation
					text={value}
					foregroundColor={foregroundColor}
					inSelect={inSelect}
					size={size}
				/>
			);
			break;
		case BOX:
			ReturnSkillRepresentationCell = (
				<BoxSkillRepresentation representationLevel={repLevel} size={size}/>
			);
			break;
		case CIRCLE:
			ReturnSkillRepresentationCell = (
				<CircleSkillRepresentation representationLevel={repLevel} size={size} />
			);
			break;
		default:
			break;
	}
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				backgroundColor:
					backgroundColor === null || backgroundColor === ""
						? "rgba(0,0,0,0)"
						: `#${backgroundColor}`,
				width: backgroundWidth,
				height: backgroundHeight,
				borderRadius: backgroundWidth !== "100%" ? "3px" : 0,
				border: showBorder ? border : 0,
			}}
		>
			{ReturnSkillRepresentationCell}
		</Box>
	);
}

export default SkillRepresentationCell;
