export const ALPHABET = [
	{
		level: 0,
		repLevel: "empty",
		repCode: "alphabet-1",
		backColorCode: "",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "Level 0",
		desc: "0",
	},
	{
		level: 1,
		repLevel: "full",
		repCode: "alphabet-2",
		backColorCode: "DCEDC8",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "Level 1",
		desc: "1",
	},
	{
		level: 2,
		repLevel: "three-quarter",
		repCode: "alphabet-3",
		backColorCode: "FECDD2",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "Level 2",
		desc: "2",
	},
	{
		level: 3,
		repLevel: "empty",
		repCode: "alphabet-4",
		backColorCode: "EEEEEE",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "Level 3",
		desc: "3",
	},
	{
		level: 4,
		repLevel: "empty",
		repCode: "alphabet-1",
		backColorCode: "",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "",
		desc: "",
	},
	{
		level: 5,
		repLevel: "empty",
		repCode: "alphabet-1",
		backColorCode: "",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "",
		desc: "",
	},
	{
		level: 6,
		repLevel: "empty",
		repCode: "alphabet-1",
		backColorCode: "",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "",
		desc: "",
	},
	{
		level: 7,
		repLevel: "empty",
		repCode: "alphabet-1",
		backColorCode: "",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "",
		desc: "",
	},
	{
		level: 8,
		repLevel: "empty",
		repCode: "alphabet-1",
		backColorCode: "",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "",
		desc: "",
	},
	{
		level: 9,
		repLevel: "empty",
		repCode: "alphabet-1",
		backColorCode: "",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "",
		desc: "",
	},
];
