//import Button from "@gigandtake/button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector } from "react-redux";

import Button from "../../../../../design/button";

type EditActionButtonsProps = {
	handleClose: (() => void) | undefined;
	isValid: boolean;
	dirty: boolean;
	isSubmitting: boolean;
	isDisabled: boolean;
};

const EditActionButtons: React.FC<EditActionButtonsProps> = ({
	handleClose,
	isValid,
	dirty,
	isSubmitting,
	isDisabled,
}) => {
	const editDraftShiftErrorMessage = useSelector(
		(state: any) => state.supervisor?.editDraftShift?.errorMessage
	);
	const userRoles = useSelector((state: any) => state.user.userData.userRoles);

	return (
		<Box
			display='flex'
			mt={6}
			width='100%'
			justifyContent='flex-end'
			alignItems='center'
		>
			{editDraftShiftErrorMessage ? (
				<Typography color='secondary'>{editDraftShiftErrorMessage}</Typography>
			) : null}
			<Box ml={2} display='flex' justifyContent='space-between' width='220px'>
				<Button color='primary' variant='text' onClick={handleClose}>
					Cancel
				</Button>
				<Button
					color='primary'
					type='submit'
					disabled={
						(Array.isArray(userRoles) ? userRoles.includes("ReadOnly") : false)
							? true
							: !isValid || !dirty || isSubmitting || isDisabled
					}
				>
					{isSubmitting ? (
						<div style={{ position: "relative" }}>
							<CircularProgress
								size={25}
								style={{ position: "absolute", left: "50%" }}
							/>{" "}
							Saving...
						</div>
					) : (
						"Save Shift"
					)}
				</Button>
			</Box>
		</Box>
	);
};

export default EditActionButtons;
