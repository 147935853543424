/* eslint-disable simple-import-sort/imports */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	closeWorkerInsightDetails,
	fetchWorkerInsightDetails,
} from "../slices/worker-insight-report-slice";
import { WorkerInsightRootState } from "../slices/insight-report-types";
import {
	fetchSkills,
	getSkillPriorities,
} from "../../skills/store/skills-slice";
import { fetchWorkerProfile } from "../../workers/store/manage-workers-slice";

const useWorkerDetailsInsightController = ({
	workerId,
}: {
	workerId: string;
}) => {
	const dispatch = useDispatch();

	const workerInsightReportData = useSelector(
		(state: WorkerInsightRootState) =>
			state.workerInsightReportData.workerInsightReportData
	);
	const fetchWorkerInsightDetailsStatus = useSelector(
		(state: WorkerInsightRootState) =>
			state.workerInsightReportData?.fetchWorkerInsightDetails?.status
	);

	const isSkillPriorityEnabled = useSelector(
		(state: any) => state.user.userData.skillPriorityEnabled
	);

	const allSkillLevels = useSelector(
		(state: WorkerInsightRootState) =>
			state.workerInsightReportData.skillsLevelLegendsData
	);

	useEffect(() => {
		if (workerId) {
			dispatch(getSkillPriorities());
			dispatch(fetchSkills());
			dispatch(fetchWorkerProfile(workerId));
			!isSkillPriorityEnabled && dispatch(fetchWorkerInsightDetails(workerId));
		}
	}, [workerId, isSkillPriorityEnabled, dispatch]);

	return [
		{
			allSkillLevels,
			workerInsightReportData,
			fetchWorkerInsightDetailsStatus,
		},
		{
			dispatch,
			closeWorkerInsightDetails,
		},
	];
};

export default useWorkerDetailsInsightController;
