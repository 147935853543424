/* eslint-disable no-mixed-spaces-and-tabs */
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
	Box,
	Checkbox,
	Divider,
	Stack,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

import { AllocationSettings } from "../../../../../store/gat-admin-slice-types";
import InputButtonGroup from "./input-button-group";

const defaultWindowTooltip =
	"Time frame for workers to mark interest in a published shift. After this window,  shift will be assigned based on allocation rule.";
const urgentWindowTooltip =
	"An urgent shift is when the default application window is more than the time between shift publish and shift start time. The window for an urgent shift has to be shorter than the default application window. Recommended - 15minutes.";
const tieBreakerTooltip =
	"If there are 2 or more eligible workers who have marked interest below order decides which worker will be assigned the shift.";

const Title: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<Typography
		variant='body1'
		sx={{
			fontFamily: "Roboto",
			fontSize: "16px",
			fontWeight: 400,
			lineHeight: "24px",
			letterSpacing: "0.15px",
			textAlign: "left",
		}}
	>
		{children}
	</Typography>
);

type ConfigurationFormProps = {
	shiftAllocationRules: any;
	setShiftAllocationRules: any;
	id?: number;
};

const ConfigurationForm = ({
	shiftAllocationRules,
	setShiftAllocationRules,
	id,
}: ConfigurationFormProps) => {
	const config = shiftAllocationRules.find(
		(rule: { shiftAllocationTypeId: number }) =>
			rule.shiftAllocationTypeId === id
	);

	return (
		<Box width={900}>
			<Divider />
			<Box display='flex' justifyContent='space-between'>
				<Box my={2}>
					<Box display='flex' alignItems='center'>
						<Title>DEFAULT APPLICATION WINDOW</Title>
						<Tooltip title={defaultWindowTooltip}>
							<InfoOutlinedIcon sx={{ marginLeft: "8px", fontSize: "18px" }} />
						</Tooltip>
					</Box>

					<Box my={2} display='flex'>
						<Box display='flex' alignItems='center' mr={2}>
							<InputButtonGroup
								handleIncrement={() =>
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													defaultWindowSizeMins: rule.defaultWindowSizeMins
														? Math.floor(rule.defaultWindowSizeMins / 60) * 60 +
														  60
														: 60,
												};
											}
											return rule;
										})
									)
								}
								handleDecrement={() =>
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													defaultWindowSizeMins:
														Math.floor(rule.defaultWindowSizeMins / 60) * 60 -
														60,
												};
											}
											return rule;
										})
									)
								}
								value={
									config?.defaultWindowSizeMins
										? Math.floor(config?.defaultWindowSizeMins / 60)
										: 0
								}
							/>
							<Typography ml={1}>hours</Typography>
						</Box>
					</Box>
				</Box>
				<Box my={2}>
					<Box display='flex' alignItems='center'>
						<Title>APPLICATION WINDOW - URGENT SHIFT</Title>
						<Tooltip title={urgentWindowTooltip}>
							<InfoOutlinedIcon sx={{ marginLeft: "8px", fontSize: "18px" }} />
						</Tooltip>
					</Box>

					<Box my={2} display='flex'>
						<Box display='flex' alignItems='center'>
							<InputButtonGroup
								handleIncrement={() =>
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													urgentWindowSizeMins: rule.urgentWindowSizeMins
														? rule.urgentWindowSizeMins + 1
														: 1,
												};
											}
											return rule;
										})
									)
								}
								handleDecrement={() =>
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													urgentWindowSizeMins: rule.urgentWindowSizeMins - 1,
												};
											}
											return rule;
										})
									)
								}
								value={config?.urgentWindowSizeMins ?? 0}
							/>
							<Typography ml={1}>minutes</Typography>
						</Box>
					</Box>
				</Box>
				<Box my={2} />
			</Box>
			<Box>
				<Box display='flex' alignItems='center'>
					<Title>Worker Preference for Shift Allocation</Title>
				</Box>

				<Stack direction='column' alignItems='start'>
					<Chip
						avatar={<Avatar>1</Avatar>}
						label='Worker’s Workcenter is ShiftWorkcenter AND Worker’s Primary Job is Shift Job Role (sorted by Lowest Overtime and Lowest EmployeeID)'
						sx={{ marginTop: "8px" }}
					/>

					<Chip
						avatar={<Avatar>2</Avatar>}
						label='Worker’s Workcenter is ShiftWorkcenter AND Worker’s Primary Job is NOT Shift Job Role (sorted by Lowest Overtime and Lowest EmployeeID)'
						sx={{ marginTop: "8px" }}
					/>
					<Chip
						avatar={<Avatar>3</Avatar>}
						label='Worker’s Workcenter is NOT ShiftWorkcenter AND Worker’s Primary Job is NOT Shift Job Role (sorted by Lowest Overtime and Lowest EmployeeID)'
						sx={{
							marginTop: "8px",
							height: "auto",
							"& .MuiChip-label": {
								display: "block",
								whiteSpace: "normal",
							},
						}}
					/>
				</Stack>
			</Box>
			<Box mt={4}>
				<Box display='flex' alignItems='center'>
					<Title>Force Assign Workers</Title>
					<Switch
						checked={!!config?.forceAssignSettings?.isEnabled}
						onChange={(e) => {
							setShiftAllocationRules(
								shiftAllocationRules.map((rule: AllocationSettings) => {
									if (rule.shiftAllocationTypeId === id) {
										return {
											...rule,
											forceAssignSettings: {
												...rule.forceAssignSettings,
												isEnabled: e.target.checked,
												applicableBeforeDays: e.target.checked ? 2 : 0,
											},
										};
									}
									return rule;
								})
							);
						}}
					/>
				</Box>
				{config?.forceAssignSettings?.isEnabled ? (
					<Box>
						<Box display='flex' alignItems='center' ml={2}>
							<Typography variant='body2'>Allow force OT assignment</Typography>
							<TextField
								size='small'
								variant='filled'
								type='number'
								sx={{
									width: 50,
									height: 40,
									marginLeft: "8px",
									marginRight: "8px",
									"& .MuiFilledInput-input": {
										padding: "8px 14px",
									},
								}}
								value={config?.forceAssignSettings?.applyBeforeDays ?? 0}
								onChange={(e) =>
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													forceAssignSettings: {
														...rule.forceAssignSettings,
														applyBeforeDays: parseInt(e.target.value),
													},
												};
											}
											return rule;
										})
									)
								}
							/>
							<Typography variant='body2'>
								days before the Shift starts
							</Typography>
						</Box>
						<Box display='flex' alignItems='center' ml={1}>
							<Checkbox
								checked={
									!!config?.forceAssignSettings?.isManualAssignmentEnabled
								}
								onChange={(e) => {
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													forceAssignSettings: {
														...rule.forceAssignSettings,
														isManualAssignmentEnabled: e.target.checked,
													},
												};
											}
											return rule;
										})
									);
								}}
							/>
							<Typography variant='body2'>
								Manual - Supervisors can assign Workers manually
							</Typography>
						</Box>
						<Box>
							<Box display='flex' alignItems='center' ml={1}>
								<Checkbox
									checked={
										!!config?.forceAssignSettings?.isAutomaticAssignmentEnabled
									}
									onChange={(e) => {
										setShiftAllocationRules(
											shiftAllocationRules.map((rule: AllocationSettings) => {
												if (rule.shiftAllocationTypeId === id) {
													return {
														...rule,
														forceAssignSettings: {
															...rule.forceAssignSettings,
															isAutomaticAssignmentEnabled: e.target.checked,
														},
													};
												}
												return rule;
											})
										);
									}}
								/>
								<Typography variant='body2'>
									Automatic - Workers will be assigned automatically based on a
									defined preference order
								</Typography>
							</Box>
							<Stack direction='column' alignItems='start' ml={6}>
								<Chip
									avatar={<Avatar>1</Avatar>}
									label='Worker’s Workcenter is ShiftWorkcenter AND Worker’s Primary Job is Shift Job Role (sorted by Lowest Overtime and Highest EmployeeID)'
									sx={{
										marginTop: "8px",
										height: "auto",
										"& .MuiChip-label": {
											display: "block",
											whiteSpace: "normal",
										},
									}}
								/>

								<Chip
									avatar={<Avatar>2</Avatar>}
									label='Worker’s Workcenter is ShiftWorkcenter AND Worker’s Primary Job is NOT Shift Job Role (sorted by Lowest Overtime and Highest EmployeeID)'
									sx={{
										marginTop: "8px",
										height: "auto",
										"& .MuiChip-label": {
											display: "block",
											whiteSpace: "normal",
										},
									}}
								/>

								<Chip
									avatar={<Avatar>3</Avatar>}
									label='Worker’s Workcenter is NOT ShiftWorkcenter AND Worker’s Primary Job is NOT Shift Job Role (sorted by Lowest Overtime and Highest EmployeeID)'
									sx={{
										marginTop: "8px",
										height: "auto",
										"& .MuiChip-label": {
											display: "block",
											whiteSpace: "normal",
										},
									}}
								/>
							</Stack>
						</Box>
					</Box>
				) : null}
			</Box>
		</Box>
	);
};

export default ConfigurationForm;
