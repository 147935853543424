import { useSelector } from "react-redux";

import {
	SkillInsightReportSliceStateTypes,
	WorkerInsightReportSliceStateTypes,
} from "../../insight-dashboard/slices/insight-report-types";

export type RootState = {
	workerInsightReportData: WorkerInsightReportSliceStateTypes;
	manageWorkers: any;
	skillInsightReportData: SkillInsightReportSliceStateTypes;
};

const useCrossSkillingDashboardData = () => {
	const workersReportData = useSelector(
		(state: RootState) => state.workerInsightReportData.workerInsightReportData
	);
	const fetchWorkerInsightReportStatus = useSelector(
		(state: RootState) =>
			state.workerInsightReportData.fetchWorkerInsightReport.status
	);
	const allLocations = useSelector(
		(state: RootState) => state.manageWorkers.userLocations
	);
	const allSkillLevels = useSelector(
		(state: RootState) => state.workerInsightReportData.skillsLevelLegendsData
	);

	const skillsReportData = useSelector(
		(state: RootState) => state.skillInsightReportData.skillInsightReportData
	);
	const fetchSkillInsightReportStatus = useSelector(
		(state: RootState) =>
			state.skillInsightReportData.fetchSkillInsightReport.status
	);
	const allPriorities = useSelector((state: any) => state.skills.skillPriorities);

	const isSkillPriorityEnabled = useSelector(
		(state: any) => state.user.userData.skillPriorityEnabled
	);

	return {
		workersReportData,
		fetchWorkerInsightReportStatus,
		allLocations,
		allSkillLevels,
		skillsReportData,
		fetchSkillInsightReportStatus,
		allPriorities,
		isSkillPriorityEnabled,
	};
};

export default useCrossSkillingDashboardData;
