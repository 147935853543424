import React from "react";

import { getRepLevel, getRepType } from "../../utils/helpers";
import { DONUT, EMPTY } from "./constants/constants";
import SkillRepresentationCell from "./skill-representaion-cell";

type SkillCellProps = {
	value: number;
	height: number;
	width: number;
	backgroundWidth: number | string;
	backgroundHeight: number | string;
	skillLevels: any;
	inSelect?: boolean;
};

type SkillLevel = {
	level: number;
	repCode: string;
	value: number;
	backColorCode: string;
	foreColorCode: string;
};

const SkillCell: React.FC<SkillCellProps> = ({
	value,
	height,
	width,
	backgroundWidth,
	backgroundHeight,
	skillLevels,
	inSelect = true,
}) => {
	if (value >= 0) {
		const level = skillLevels.find(
			(sLevel: SkillLevel) => sLevel.level === value
		);
		let repType = DONUT;
		let repLevel = EMPTY;
		let levelValue = value;
		// CHANGE to use in skill chip in select skills  backgroundColor
		let backgroundColor = "";
		let foregroundColor = "";
		if (level) {
			repType = getRepType(level.repCode);
			repLevel = getRepLevel(level.repCode, level.level);
			levelValue = level.value;
			backgroundColor = level.backColorCode;
			foregroundColor = level.foreColorCode;
		}
		return (
			<SkillRepresentationCell
				level={value}
				value={levelValue}
				representationType={repType}
				representationLevel={repLevel}
				backgroundColor={backgroundColor}
				foregroundColor={foregroundColor}
				border={1}
				height={height ? height : 10}
				width={width ? width : 10}
				backgroundWidth={backgroundWidth ? backgroundWidth : "25px"}
				backgroundHeight={backgroundHeight ? backgroundHeight : "25px"}
				inSelect={inSelect}
			/>
		);
	} else {
		return <></>;
	}
};

export default SkillCell;
