import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";

import Loading from "../../../../../../components/Loading";
import useSkillInsightReportData from "../../../../controllers/use-insight-skill-data";
import InterestedWorkers from "./interested-workers";
import WorkersWithSkill from "./workers-with-skill";

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 1 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const SkillInterestedTabs = () => {
	const { skillInsightDetailsStatus, skillsInsightData, allSkillLevels } =
		useSkillInsightReportData();
	const [value, setValue] = useState(0);

	const onHandleTabChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={onHandleTabChange}
					aria-label='workers list'
				>
					<Tab label='Interested Workers' {...a11yProps(0)} />
					<Tab label='Workers with skill' {...a11yProps(1)} />
				</Tabs>
			</Box>

			<TabPanel value={value} index={0}>
				{skillInsightDetailsStatus === "pending" ? (
					<Loading />
				) : (
					<InterestedWorkers
						interestedWorkersList={
							skillsInsightData.interestedWorkersList ?? []
						}
					/>
				)}
			</TabPanel>
			<TabPanel value={value} index={1}>
				{skillInsightDetailsStatus === "pending" ? (
					<Loading />
				) : (
					<WorkersWithSkill
						workersWithSkill={skillsInsightData.workersList ?? []}
						allSkillLevels={allSkillLevels}
					/>
				)}
			</TabPanel>
		</Box>
	);
};

export default SkillInterestedTabs;
