import React from "react";
import { useSelector } from "react-redux";

import { WorkerInsightDetailsModel, WorkerInsightRootState } from "../slices/insight-report-types";

const useInsightWorkerDetailsByLevelController = () => {

    const workerInsightDetailsData = useSelector((state: WorkerInsightRootState) => state.workerInsightReportData.workerInsightDetailsData)

    const [searchText, setSearchText] = React.useState("")
    const [expanded, setExpanded] = React.useState<string[]>([])
    
    let searchWorkerInsightDetailsData: WorkerInsightDetailsModel  = workerInsightDetailsData;

    if (searchText?.length > 0) {

        let skills: any = [];
        let interestedSkills: any = []

        const searchTextLowerCase = searchText.toLowerCase().replace(/\s/g, "");

        skills = workerInsightDetailsData?.skills?.filter((skill) => {
            return skill.name.toLowerCase().replace(/\s/g, "").includes(searchTextLowerCase)
        })

        interestedSkills = workerInsightDetailsData?.interestedSkills?.filter((interestedSkill) => {
            return interestedSkill.toLowerCase().replace(/\s/g, "").includes(searchTextLowerCase)
        })

        searchWorkerInsightDetailsData = { skills: skills, interestedSkills: interestedSkills, workerId: workerInsightDetailsData.workerId }
    }

    return [
        {
            searchText,
            searchWorkerInsightDetailsData,
            expanded
        },
        {
            setSearchText,
            setExpanded
        }
    ]
}

export default useInsightWorkerDetailsByLevelController;