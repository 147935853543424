// TODO - Type 'any' needs to be fixed.
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box, Button, Divider } from "@mui/material";
import isAfter from "date-fns/isAfter";
import { useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ConfirmDialog from "../../ConfirmDialog";
import {
	deleteDraftShiftAsync,
	editDraftShiftDialogClose,
} from "../store/scheduler-slice";

interface ShiftData {
	id: number;
	shiftId: string;
	startDate: string | Date;
}

interface IDeleteShiftProps {
	shiftData: ShiftData;
	toggleVisibility: (val: boolean) => void;
	isDraftShift?: boolean;
	openShiftCancelDialog?: () => void;
	openDeleteDraftShiftDialog?: () => void;
}

function DeleteShift({
	shiftData,
	toggleVisibility,
	isDraftShift = false,
	openShiftCancelDialog,
	openDeleteDraftShiftDialog,
}: IDeleteShiftProps) {
	const userRoles = useSelector((state: any) => state.user.userData.userRoles);

	const shiftDate = shiftData?.startDate;

	const isPastShift = (shiftDateTime: Date | string) => {
		return isAfter(new Date(), new Date(shiftDateTime));
	};

	return (
		<>
			{!isPastShift(shiftDate) ? (
				<Box>
					<Button
						color='error'
						size='large'
						variant='text'
						startIcon={<CancelOutlinedIcon />}
						onClick={() => {
							if (!isDraftShift && openShiftCancelDialog) {
								openShiftCancelDialog();
							} else if (isDraftShift && openDeleteDraftShiftDialog) {
								openDeleteDraftShiftDialog();
							}
							toggleVisibility(false);
						}}
						sx={{
							textTransform: "none",
							width: "100%",
							justifyContent: "flex-start",
							paddingLeft: "24px",
						}}
						disabled={
							Array.isArray(userRoles) ? userRoles.includes("ReadOnly") : false
						}
					>
						{isDraftShift ? "Delete Draft Shift" : "Cancel Shift"}
					</Button>
					<Divider />
				</Box>
			) : null}
		</>
	);
}

export default DeleteShift;
