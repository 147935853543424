import { Box } from "@mui/material";
import React from "react";

import { FULL, HALF, QUARTER, THREE_QUARTER } from "../constants/constants";

const pathColor = "#d9d9d9";
const mainColor = "#367549";

const s1 = {
	borderBottom: `6px solid ${mainColor}`,
	borderTop: `6px solid ${pathColor}`,
	borderLeft: `6px solid ${pathColor}`,
	borderRight: `6px solid ${pathColor}`,
};

const s2 = {
	borderBottom: `6px solid ${mainColor}`,
	borderTop: `6px solid ${pathColor}`,
	borderLeft: `6px solid ${mainColor}`,
	borderRight: `6px solid ${pathColor}`,
};

const s3 = {
	borderBottom: `6px solid ${mainColor}`,
	borderTop: `6px solid ${mainColor}`,
	borderLeft: `6px solid ${mainColor}`,
	borderRight: `6px solid ${pathColor}`,
};
const s5 = {
	borderBottom: `6px solid ${pathColor}`,
	borderTop: `6px solid ${pathColor}`,
	borderLeft: `6px solid ${pathColor}`,
	borderRight: `6px solid ${pathColor}`,
};

const s4 = {
	borderBottom: `6px solid ${mainColor}`,
	borderTop: `6px solid ${mainColor}`,
	borderLeft: `6px solid ${mainColor}`,
	borderRight: `6px solid ${mainColor}`,
};
function SkillDonutRepresentation({
	representationLevel,
	size = 20,
}: {
	representationLevel?: number | string;
	size?: number;
}) {
	const ob = {
		width: 	`${size}px`,
		height: `${size}px`,
		transform: "rotate(225deg)",
		borderRadius: "100%",
	};

	let style;
	switch (representationLevel) {
		case 1:
		case QUARTER:
			style = s1;
			break;
		case 2:
		case HALF:
			style = s2;
			break;
		case 3:
		case THREE_QUARTER:
			style = s3;
			break;
		case 4:
		case FULL:
			style = s4;
			break;
		default:
			style = s5;
	}

	return <Box style={{ ...ob, ...style }}></Box>;
}

export default SkillDonutRepresentation;
