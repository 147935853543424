/* eslint-disable no-mixed-spaces-and-tabs */
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
	CommunicationSettings,
	EssentialLocationDetails,
	ShiftSettings,
	Teams,
	WorkCenters,
} from "../../../../../../export/gat-admin";
import {
	getAllJobsByLocation,
	getAllShiftAllocationRules,
	getAllTimeZones,
	getLocations,
	// eslint-disable-next-line sort-imports
	getLocationSettings,
	resetCreateNewLocation,
	updateCreateNewLocation,
} from "../../../../../../export/gat-admin";
import { getWorkerNotificationTypes } from "../../../store/gat-admin-slice";
import {
	CompanyLocation,
	GATAdminCompany,
	LocationType,
} from "../../../store/gat-admin-slice-types";

interface ILocationFormPageProps {
	creatingNewLocation: boolean;
	locationData: LocationType;
	createNewLocationStatus: string;
	newLocationId: any;
	companyDataValue: GATAdminCompany;
	openDialog: any;
	setWorkCenterData: any;
	recentlyUpdatedLocationId: any;
	locations: CompanyLocation[];
	closeLocationForm: any;
	createNewLocationErrorMessage: any;
}

const LocationFormPage = ({
	creatingNewLocation,
	locationData,
	createNewLocationStatus,
	newLocationId,
	companyDataValue,
	openDialog,
	setWorkCenterData,
	recentlyUpdatedLocationId,
	locations,
	closeLocationForm,
	createNewLocationErrorMessage,
}: any) => {
	const dispatch = useDispatch();

	useEffect(() => {
		try {
			dispatch(getAllTimeZones());
			dispatch(getAllShiftAllocationRules());
		} catch (error) {
			// Do nothing
		}
	}, [dispatch]);

	useEffect(() => {
		if (!creatingNewLocation && locationData.id) {
			dispatch(getAllJobsByLocation(locationData.id));
			dispatch(getLocationSettings(locationData.id));
			dispatch(getWorkerNotificationTypes(locationData.id));
		}
	}, [dispatch, creatingNewLocation, locationData]);

	useEffect(() => {
		if (createNewLocationStatus === "fulfilled" && newLocationId) {
			dispatch(getLocationSettings(newLocationId));
			dispatch(getWorkerNotificationTypes(newLocationId));
			dispatch(getAllShiftAllocationRules());
			dispatch(updateCreateNewLocation(false));
		}
	}, [dispatch, createNewLocationStatus, newLocationId, companyDataValue]);

	return (
		<>
			<EssentialLocationDetails
				locationData={locationData}
				creatingNewLocation={creatingNewLocation}
				closeLocationForm={closeLocationForm}
			/>
			{!creatingNewLocation ? (
				<>
					<WorkCenters
						locations={locations.filter(
							(loc: CompanyLocation) => loc.id === locationData.id
						)}
						openDialog={openDialog}
						setWorkCenterData={setWorkCenterData}
						recentlyUpdatedLocationId={recentlyUpdatedLocationId}
					/>

					<CommunicationSettings
						locationData={locationData}
						creatingNewLocation={creatingNewLocation}
						entity='location'
					/>
					<ShiftSettings
						locationData={
							locations.find(
								(loc: CompanyLocation) => loc.id === locationData.id
							) ?? locationData
						}
						creatingNewLocation={creatingNewLocation}
						entity='location'
					/>
				</>
			) : null}

			{/* <LimitsControls entity='location' /> */}

			{/* <ShiftSettings
					locationData={locationData}
					creatingNewLocation={creatingNewLocation}
					entity='location'
				/> */}
			{/* <WorkerAppSettings entity='location' /> */}

			{/* <AddOnFeatures entity='location' /> */}

			<Snackbar
				open={createNewLocationStatus === "error"}
				autoHideDuration={2000}
				onClose={() => {
					dispatch(resetCreateNewLocation());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{createNewLocationErrorMessage ??
						"An Error Occurred. Please try again."}
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={createNewLocationStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetCreateNewLocation());
					dispatch(getLocations(companyDataValue.id));
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{"New location added!"}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default LocationFormPage;
