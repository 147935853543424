/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable simple-import-sort/imports */
import React, { useMemo } from "react";
import { Box, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SortedDatagridPro6 from "../../../../design/sorted-datagrid-pro-6";
import CrossSkillLegends from "../../../cross-skilling/ui/cross-skill-header/ui/cross-skill-ledgends";
import SkeletonLoadingOverlay from "../../../../utils/use-skeleton-loading-overlay";
import useTableHeight from "../../../../utils/use-table-height";
import Highlight from "../../../Highlight";
import { openSkillInsightDetails } from "../../slices/skill-insight-report-slice";

const SkillInsightReportTable = ({
	selectedKPI,
	skillsReportData,
	searchText,
	fetchSkillInsightReportStatus,
	allSkillLevels,
	overAllColumns,
	dispatch,
	allLocations,
	priorities,
}: any) => {
	const { boxRef } = useTableHeight();
	const columns = useMemo(() => {
		const col: any = [];

		const isColumnVisible = (field: string) =>
			overAllColumns.find(
				(item: { field: string; checked: boolean }) =>
					item.field === field && item.checked
			);

		// Skill Name column
		if (isColumnVisible("skillName")) {
			col.push({
				field: "skillName",
				headerName: "Skill Name",
				minWidth: 160,
				flex: 1,
				// align: "left",
				// headerAlign: "center",
				renderCell: (params: any) => (
					<Tooltip title={params.row.skillName}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								marginLeft: "12px",
							}}
						>
							<Highlight searchText={searchText} value={params.row.skillName} />
						</span>
					</Tooltip>
				),
				renderHeader: () => (
					<Tooltip title='Skill Name'>
						<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
							Skill Name
						</span>
					</Tooltip>
				),
			});
		}

		// Skill Priority column
		if (isColumnVisible("skillPriority")) {
			col.push({
				field: "skillPriority",
				headerName: "Skill Priority",
				minWidth: 160,
				flex: 1,
				align: "center",
				headerAlign: "center",
				renderCell: (params: any) => (
					<Tooltip title={params.row.skillPriority}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								marginLeft: "12px",
							}}
						>
							<Highlight
								searchText={searchText}
								value={params.row.skillPriority}
							/>
						</span>
					</Tooltip>
				),
				renderHeader: () => (
					<Tooltip title='Skill Priority'>
						<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
							Skill Priority
						</span>
					</Tooltip>
				),
			});
		}

		// Interested workers count column
		if (isColumnVisible("interestedWorkersCount")) {
			col.push({
				field: "interestedWorkersCount",
				headerName: "Interested Workers",
				minWidth: 205,
				flex: 1,
				align: "center",
				headerAlign: "center",
				renderHeader: () => (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
							Interested Workers
						</span>
						<Tooltip title='Count of workers who have shown interest in this skill'>
							<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />
						</Tooltip>
					</Box>
				),
			});
		}

		// Worker count column
		if (isColumnVisible("totalWorkers")) {
			col.push({
				field: "totalWorkers",
				headerName: "Total Workers",
				minWidth: 190,
				flex: 1,
				align: "center",
				headerAlign: "center",
				renderHeader: () => (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
							Workers with Skill
						</span>
						<Tooltip title='Count of workers have the skill in their profile'>
							<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />
						</Tooltip>
					</Box>
				),
			});
		}

		// Dynamic skill levels columns
		if (
			allSkillLevels?.length > 0 &&
			selectedKPI !== "interestedWorkersKPI" &&
			selectedKPI !== "interestedWorkers"
		) {
			allSkillLevels.forEach((skillLevel: any) => {
				col.push({
					field: `workersCountAtL${skillLevel.level}`,
					headerName: skillLevel.name,
					minWidth: 135,
					flex: 1,
					align: "center",
					headerAlign: "center",
					renderHeader: () => (
						<Box style={{ fontWeight: "bold" }}>
							<CrossSkillLegends
								isSkillLevelShownInTableHeader={true}
								allSkillLevels={[allSkillLevels[skillLevel.level]]}
							/>
						</Box>
					),
					renderCell: (params: any) =>
						params.row[`workersCountAtL${skillLevel.level}`] || "-",
				});
			});
		}

		return col;
	}, [overAllColumns, searchText, allSkillLevels, selectedKPI]);

	const rows = useMemo(() => {
		return skillsReportData
			.map((item: any, index: number) => {
				const workersCounts: any = {};
				let workersCoverage = 0;

				item.workerCountByLevel.forEach((skill: any) => {
					workersCounts[`workersCountAtL${skill.level}`] =
						item.workerCountByLevel.reduce(
							(total: number, current: any) =>
								current.level === skill.level
									? total + current.workersCount
									: total,
							0
						);
					const lev = allSkillLevels.find((x: any) => x.level === skill.level);
					if (lev && lev.isQualifiedAsSkilled) {
						workersCoverage += skill.workersCount;
					}
				});

				const priority = priorities?.[item.priorityId]
					? `${priorities[item.priorityId].code} (${
							priorities[item.priorityId].name
					  })`
					: "-";

				return {
					id: index,
					location: item.location,
					skillName: item.skillName.trim(),
					...workersCounts,
					interestedWorkersCount: item.interestedWorkers.length,
					totalWorkers: workersCoverage,
					skillId: item.skillId,
					skillPriority: priority,
				};
			})
			.sort((a: any, b: any) => a.skillName.localeCompare(b.skillName));
	}, [skillsReportData, priorities, allSkillLevels]);

	const handleRowClick = (params: any) => {
		dispatch(
			openSkillInsightDetails({
				skillId: params.row.skillId,
				locations: allLocations,
				selectedKPI: selectedKPI,
			})
		);
	};

	return (
		<Box
			ref={boxRef}
			style={{
				overflow: "hidden",
				height: "calc(100vh - 320px)",
				width: "100%",
				minHeight: "200px",
			}}
		>
			<SortedDatagridPro6
				rows={rows || []}
				columns={columns}
				onRowClick={handleRowClick}
				experimentalFeatures={{ columnGrouping: true }}
				loading={fetchSkillInsightReportStatus === "pending"}
				defaultSortField='skillName'
				slots={{
					loadingOverlay: () => (
						<SkeletonLoadingOverlay
							columnData={{
								columnName: "skillName",
								columnStyle: { marginLeft: "12px" },
							}}
						/>
					),
				}}
				disableColumnFilter
				disableColumnMenu
				pagination
				disableColumnSelector
				disableDensitySelector
				disableColumnReorder
				sx={{
					overflow: "hidden",
					border: "none !important",
					"& .MuiDataGrid-row": {
						cursor: "pointer",
					},
					"& .MuiDataGrid-cell:focus": {
						outline: "none",
					},
				}}
			/>
		</Box>
	);
};

export default SkillInsightReportTable;
