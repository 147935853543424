import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useState } from "react";

import { Location, SkillLevel } from "../../../slices/insight-report-types";
import SKillInsightDetailsWorkerTab from "./skill-insight-details-worker-tab";

function TabPanel(props: any) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ p: 1, }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface ISkillInsightDetailsTabsProps {
    allSkillLevels: SkillLevel[]
    fetchSkillInsightDetailsStatus: "idle" | "pending" | "fulfilled" | "error";
    allLocations: Location[]
}
export default function SkillInsightDetailsTabs({ allSkillLevels, fetchSkillInsightDetailsStatus, allLocations }: ISkillInsightDetailsTabsProps) {

    const [value, setValue] = useState(0);
    
    const onHandleTabChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={onHandleTabChange}
                    aria-label='workers list'>
                    <Tab label='Workers' {...a11yProps(0)} />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <SKillInsightDetailsWorkerTab allSkillLevels={allSkillLevels} fetchSkillInsightDetailsStatus={fetchSkillInsightDetailsStatus} allLocations={allLocations} />
            </TabPanel>

        </Box>
    );
}
