import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import React from "react";

const ProfileHeaderSkeleton = () => {
	return (
		<Box display='flex' alignItems='center' justifyContent='flex-start' m={2}>
			<Stack ml={1} spacing={1}>
				<Skeleton variant='rectangular' width={140} height={18} />
				<Skeleton variant='rectangular' width={100} height={14} />
			</Stack>
		</Box>
	);
};

export default ProfileHeaderSkeleton;
