import PageLayout from "@gigandtake/page-layout";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ConfirmProvider } from "material-ui-confirm";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { changeSelectedMenuItemId } from "../../../export/drawer";
import useTableHeight from "../../../ts/utils/use-table-height";
import NavDrawer from "../../components/drawer/ui";
import {
	SkillInsightRootState,
	WorkerInsightRootState,
} from "../insight-dashboard/slices/insight-report-types";
import SkillInsightDetails from "../insight-dashboard/ui/skill-insight/skill-insight-details";
import WorkerInsightDetails from "../insight-dashboard/ui/worker-insight/worker-insight-details";
import ManageCrossSkillingDashboard from "./ui/cross-skilling-dashboard-dashboard";

function CrossSkillingDashboard() {
	const dispatch = useDispatch();
	useEffect(() => {
		// To select skill matrix menu item
		dispatch(changeSelectedMenuItemId(6));
	}, [dispatch]);

	const { height, boxRef } = useTableHeight();

	const isWorkerInsightDetailsOpen = useSelector(
		(state: WorkerInsightRootState) =>
			state.workerInsightReportData.workerInsightPanelDetails.isOpen
	);

	const isSkillInsightDetailsOpen = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.skillInsightPanelDetails.isOpen
	);

	const workerId = useSelector(
		(state: WorkerInsightRootState) =>
			state.workerInsightReportData.workerInsightPanelDetails.workerId
	);

	const skillId = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.skillInsightPanelDetails.skillId
	);

	const workerInsightLocations = useSelector(
		(state: WorkerInsightRootState) =>
			state.workerInsightReportData.workerInsightPanelDetails.locations
	);

	const skillInsightLocations = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.skillInsightPanelDetails.locations
	);

	const fullTimeSchedule = useSelector(
		(state: any) => state.user.userData.fullTimeSchedule
	);

	const selectedKPI = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.skillInsightPanelDetails.selectedKPI
	);

	return (
		<Box sx={{ display: "flex", backgroundColor: "#F5F5F5" }} ref={boxRef}>
			<CssBaseline />

			<NavDrawer keepSettingOpen={false} keepReportOpen={false} />

			<Box sx={{ flexGrow: 1, overflow: "hidden" }}>
				<Grid container>
					<Grid
						item
						xs={
							isWorkerInsightDetailsOpen || isSkillInsightDetailsOpen ? 8.5 : 12
						}
					>
						<PageLayout>
							<ConfirmProvider>
								<ManageCrossSkillingDashboard
									fullTimeSchedule={fullTimeSchedule}
								/>
							</ConfirmProvider>
						</PageLayout>
					</Grid>

					{/* This is used to show the side panels for every row click of workers or skills grid */}
					{isWorkerInsightDetailsOpen ? (
						<Grid item xs={3.5}>
							<WorkerInsightDetails
								workerId={workerId}
								allLocations={workerInsightLocations}
								sidePanelHeight={70}
							/>
						</Grid>
					) : null}

					{isSkillInsightDetailsOpen ? (
						<Grid item xs={3.5}>
							<SkillInsightDetails
								skillId={skillId}
								allLocations={skillInsightLocations}
								sidePanelHeight={70}
								selectedKPI={selectedKPI}
							/>
						</Grid>
					) : null}
				</Grid>
			</Box>
		</Box>
	);
}

export default CrossSkillingDashboard;
