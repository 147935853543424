import PageLayout from "@gigandtake/page-layout";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { ConfirmProvider } from "material-ui-confirm";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useLocation} from "react-router-dom";

import CrossSkilling from "../../../../ts/components/cross-skilling";
import { closeSkillInsightDetails } from "../../insight-dashboard/slices/skill-insight-report-slice";
import { closeWorkerInsightDetails } from "../../insight-dashboard/slices/worker-insight-report-slice";
import WorkerInsightReport from "./cross-skilling-dashboard";
function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const CrossSkillingDashboardTabs = () => {
	const location = useLocation();
	const [value, setValue] = React.useState(0);

	const isSkillInsightsEnabled = useSelector(
		(state: any) => state.user.userData.skillInsightsEnabled
	);

	const dispatch = useDispatch();

	// TODO: Not required as Skill Matrix table is now default and value 0 will load that
	// TODO: Have to uncomment once HOTFIX changes are reverted and Summary page is set to value 0 again
	// useEffect(() => {
	// 	try {
	// 		const params = new URLSearchParams(location.search);
	// 		const tab = params.get("tab");
	// 		if (tab === "skill-matrix") {
	// 			setValue(1); // Set Skill matrix tab assigned to 0
	// 		} else {
	// 			setValue(0); // Set Summary tab assigned to 1
	// 		}
	// 	} catch (e) {
	// 		// Do nothing
	// 	}
	// }, [location.search]);

	useEffect(() => {
		dispatch(closeSkillInsightDetails());
		dispatch(closeWorkerInsightDetails());
	}, [dispatch]);

	const handleChange = (event: any, newValue: any) => {
		dispatch(closeSkillInsightDetails());
		dispatch(closeWorkerInsightDetails());

		setValue(newValue);
	};
	if (!isSkillInsightsEnabled)
		return (
			<PageLayout>
				<ConfirmProvider>
					<CrossSkilling />
				</ConfirmProvider>
			</PageLayout>
		);

	return (
		<Box sx={{ width: "100%", height: "100%", marginTop: -0.9 }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }} pl={1}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label='Workers and summary tabs'
					sx={{
						".MuiTab-root": {
							paddingBottom: 0.8,
						},

					}}
					TabIndicatorProps={{
						sx: {
							height: "1px",
							marginLeft:"15px",
						},
					}}
				>
					<Tab label='SKILLS MATRIX' {...a11yProps(0)}
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							fontWeight: "500",
							lineHeight: "24px",
							letterSpacing: "0.4px",
						}}
					/>
					<Tab label='SUMMARY' {...a11yProps(1)}
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							fontWeight: "500",
							lineHeight: "24px",
							letterSpacing: "0.4px",
						}}
					/>
				</Tabs>
			</Box>

			<TabPanel value={value} index={0}>
				<CrossSkilling />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<WorkerInsightReport />
			</TabPanel>
		</Box>
	);
};

export default CrossSkillingDashboardTabs;
