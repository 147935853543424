import { Box, Switch, Typography } from "@mui/material";
import React from "react";

interface IShiftPurposeProps {
	newShiftSettings: any;
	setNewShiftSettings: any;
}

const ShiftPurpose = ({
	newShiftSettings,
	setNewShiftSettings,
}: IShiftPurposeProps) => {
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				padding: "16px",
				marginTop: "32px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Allow to select Shift Purpose while posting Shifts
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Supervisors can select the Purpose of the Shift.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						checked={newShiftSettings.shiftPurpose ?? false}
						onChange={(e) =>
							setNewShiftSettings((prevSettings: any) => ({
								...prevSettings,
								shiftPurpose: e.target.checked,
							}))
						}
					/>
				</Box>
			</Box>
			{newShiftSettings.shiftPurpose ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						marginTop: "16px",
					}}
				>
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "12px",
								lineHeight: "19px",
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							Shift Purpose types
						</Typography>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "14px",
								color: "#000000DE",
								lineHeight: "20px",
							}}
						>
							Normal Overtime, Vacation Backfill, Last Minute Call Off, Peak
							Demand.
						</Typography>
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default ShiftPurpose;
