/* eslint-disable simple-import-sort/imports */
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import React from "react";
import useTableHeight from "../../../../../utils/use-table-height";
import useSkillDetailsInsightController from "../../../controllers/use-insight-skill-details-controller";
import SkillInsightDetailsTabs from "./skill-insight-details-tabs";
import {
	Location,
	SkillInsightModel,
} from "../../../slices/insight-report-types";
import ProfileHeaderSkeleton from "../../../../../../ts/components/workers/ui/worker-profile/worker-profile-tabs/ui/profile-header-skeleton";
import SkillInterestedTabs from "./skill-interested-tabs";

function SkillInsightDetails({
	skillId,
	allLocations,
	sidePanelHeight = 24,
	selectedKPI,
}: {
	skillId: string;
	allLocations: Location[];
	sidePanelHeight?: number;
	selectedKPI?: string;
}) {
	const { height, boxRef } = useTableHeight();

	const [
		{ skillsReportData, fetchSkillInsightDetailsStatus, allSkillLevels },
		{ dispatch, closeSkillInsightDetails },
	]: any = useSkillDetailsInsightController({ skillId });

	const handleOnPanelCloseClick = () => {
		if (dispatch !== undefined && closeSkillInsightDetails !== undefined) {
			dispatch(closeSkillInsightDetails());
		}
	};

	const selectedSkill = skillsReportData?.find(
		(skill: SkillInsightModel) => skill.skillId === skillId
	);

	return (
		<Box
			sx={{ backgroundColor: "white" }}
			height={height + sidePanelHeight} //- To be insync with left panel
			overflow='auto'
			ref={boxRef}
		>
			<Box
				display='flex'
				width={100}
				sx={{
					cursor: "pointer",
					"&:hover": {
						backgroundColor: "#f8f8f8",
						borderRadius: "5px",
					},
				}}
				onClick={handleOnPanelCloseClick}
				mt={4}
				ml={1}
				p={2}
			>
				<CloseIcon />

				<Typography variant='button'>Close</Typography>
			</Box>

			{fetchSkillInsightDetailsStatus === "pending" ? (
				<ProfileHeaderSkeleton />
			) : (
				<Box display='flex' flexWrap='wrap' alignItems='center' mb={1} ml={2}>
					<Typography variant='h6' mr={1}>
						{selectedSkill?.skillName}
					</Typography>
				</Box>
			)}
			{selectedKPI === "interestedWorkers" ||
			selectedKPI === "interestedWorkersKPI" ? (
				<SkillInterestedTabs />
			) : (
				<SkillInsightDetailsTabs
					fetchSkillInsightDetailsStatus={fetchSkillInsightDetailsStatus}
					allSkillLevels={allSkillLevels}
					allLocations={allLocations}
				/>
			)}
		</Box>
	);
}

export default SkillInsightDetails;
