export const BOX = [
	{
		level: 0,
		repLevel: "empty",
		repCode: "box-empty",
		backColorCode: "",
		foreColorCode: "",
		value: "AM",
		icon: "AM",
		name: "Level 0",
		desc: "0",
	},
	{
		level: 1,
		repLevel: "quarter",
		repCode: "box-quarter",
		backColorCode: "DCEDC8",
		foreColorCode: "",
		value: "4D",
		icon: "4D",
		name: "Level 1",
		desc: "1",
	},
	{
		level: 2,
		repLevel: "half",
		repCode: "box-half",
		backColorCode: "FECDD2",
		foreColorCode: "",
		value: "5H",
		icon: "5H",
		name: "Level 2",
		desc: "2",
	},
	{
		level: 3,
		repLevel: "three-quarter",
		repCode: "box-three-quarter",
		backColorCode: "EEEEEE",
		foreColorCode: "",
		value: "44",
		icon: "44",
		name: "Level 3",
		desc: "3",
	},
	{
		level: 4,
		repLevel: "full",
		repCode: "box-full",
		backColorCode: "",
		foreColorCode: "",
		value: "",
		icon: "",
		name: "",
		desc: "",
	},
];
