/* eslint-disable no-mixed-spaces-and-tabs */
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React from "react";

import Button from "../../../../../design/button";
import useSchedulerData from "../../../controllers/use-scheduler-data";

type ActionButtonsProps = {
	handleClose: (() => void) | undefined;
	isValid: boolean;
	dirty: boolean;
	isSubmitting: boolean;
	isShiftSwap?: boolean;
	isDisabled?: boolean;
	availableWorkers: number;
	jobSelected: boolean;
	skillSelected: boolean;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({
	handleClose,
	isValid,
	dirty,
	isSubmitting,
	isShiftSwap,
	isDisabled,
	availableWorkers,
	jobSelected,
	skillSelected,
}) => {
	const { createDraftShiftAsyncErrorMessage, userRoles } = useSchedulerData();

	return (
		<Box
			display='flex'
			mt={isShiftSwap ? 2 : 4}
			width='100%'
			justifyContent='space-between'
			alignItems='center'
		>
			{createDraftShiftAsyncErrorMessage ? (
				<Typography color='error'>
					{createDraftShiftAsyncErrorMessage}
				</Typography>
			) : null}
			{isShiftSwap &&
			availableWorkers === 0 &&
			(jobSelected || skillSelected) ? (
				<Typography color='error'>
					No eligible workers available to approve this shift.
				</Typography>
			) : null}
			<Box />
			<Box
				ml={2}
				display='flex'
				justifyContent='space-between'
				width={isShiftSwap ? "200px" : "220px"}
			>
				<Button color='primary' variant='text' onClick={handleClose}>
					Cancel
				</Button>
				<Button
					color='primary'
					type='submit'
					disabled={
						(Array.isArray(userRoles) ? userRoles.includes("ReadOnly") : false)
							? true
							: !isValid ||
							  !dirty ||
							  isSubmitting ||
							  isDisabled ||
							  (isShiftSwap &&
									availableWorkers === 0 &&
									(jobSelected || skillSelected))
					}
				>
					{isSubmitting ? (
						<div style={{ position: "relative" }}>
							<CircularProgress
								size={25}
								style={{ position: "absolute", left: "50%" }}
							/>{" "}
							{isShiftSwap ? "Approving" : "Creating Shift"}
						</div>
					) : isShiftSwap ? (
						"Approve"
					) : (
						"Create Shift"
					)}
				</Button>
			</Box>
		</Box>
	);
};

export default ActionButtons;
