/* eslint-disable no-mixed-spaces-and-tabs */
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { gridClasses } from "@mui/x-data-grid-pro-6";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	ColumnCell,
	ColumnEmployeeCell,
	ColumnGroupJobHeader,
	ColumnHeader,
	ColumnNoSkillHeader,
} from "../../../../../export/cross-skilling";
import SortedDatagridPro6 from "../../../../../ts/design/sorted-datagrid-pro-6";
import useCrossSkillTableController from "../../controllers/use-cross-skill-table-controller";
import {
	createSkillColumn,
	createSkillColumnWithoutJobs,
	findOtherSkills,
	skillsWithoutJobs,
} from "./utils";

export default function CrossSkillingTable({
	data,
	filters,
	allSkillLevels,
	notAssignedSkillLevel,
	allJobs,
	filterStatus,
	allOrganizations,
	dataProcessed,
	selectedColumns,
	searchText,
	getFilter,
}) {
	const {
		apiRef,
		addSkillInterestStatus,
		rejectSkillInterestStatus,
		isJobFilter,
		isSkillPriorityFilter,
		skillPriorityIds,
		isColumnGroup,
		skillLevelIds,
		jobIdsSkillsArr,
		jobIds,
		filteredWorkersByLevel,
		filteredSkills,
		workers,
		handleAddSkillInterested,
		handleSkillLevels,
		columnGroup,
		updateWorkerSkillLevelStatus,
		height,
		width,
		allSkillsOfAllLocations,
		fetchManageWorkersDataStatus,
		skillMatrixFiltersStatus,
		skillMatrixDefaultFiltersStatus,
		skillsUpdatedListInSession,
		isWorkCenterFilter,
	} = useCrossSkillTableController({
		data,
		filters,
		allSkillLevels,
		allJobs,
		getFilter,
		searchText,
	});

	const isSkillInsightsEnabled = useSelector(
		(state) => state.user.userData.skillInsightsEnabled
	);

	const isSkillPriorityEnabled = useSelector(
		(state) => state.user.userData.skillPriorityEnabled
	);
	const managerColumnSelected = !!selectedColumns.find(
		(column) => column.field === "managerName"
	);
	const employeeIdColumnSelected = !!selectedColumns.find(
		(column) => column.field === "companyEmployeeId"
	);

	const jobTitleColumnSelected = !!selectedColumns.find(
		(column) => column.field === "jobTitle"
	);

	const totalScoreColumnSelected = !!selectedColumns.find(
		(column) => column.field === "skillScore"
	);

	const workCenterColumnSelected = !!selectedColumns.find(
		(column) => column.field === "workCenter"
	);

	const shiftPatternColumnSelected = !!selectedColumns.find(
		(column) => column.field === "shiftPatternName"
	);

  const nameColumnSelected = !!selectedColumns.find(
    (column) => column.field === "name"
  );

	const isDrawerOpen = useSelector(
		(state) => state.navigationDrawer.isDrawerOpen
	);

	const [loading, setLoading] = useState(true);
	const [pinnedColumns, setPinnedColumns] = useState([
		"companyEmployeeId",
		"jobTitle",
		"name",
		"workCenter",
		"shiftPatternName",
		"managerName",
		"skillScore",
	]);
	let rows;
	let col = [];

	if (employeeIdColumnSelected) {
		// Add worker employee id column
		col.push({
			field: "companyEmployeeId",
			headerName: "Worker ID",
			width: 200,
			headerAlign: "left",
			renderHeader: () => <ColumnHeader text={"Worker ID"} />,
			renderCell: (params) => (
				<ColumnCell
					text={params.row.companyEmployeeId ?? "-"}
					searchText={searchText}
				/>
			),
			valueGetter: (params) => params.row.companyEmployeeId,
		});
	}

	if (jobTitleColumnSelected) {
		// Add worker employee id column
		col.push({
			field: "jobTitle",
			headerName: "Job Title",
			width: 200,
			headerAlign: "left",
			renderHeader: () => <ColumnHeader text={"Job Title"} />,
			renderCell: (params) => (
				<ColumnCell text={params.row.jobTitle ?? "-"} searchText={searchText} />
			),
			valueGetter: (params) =>
				params.row.jobTitle ? params.row.jobTitle.trim().toLowerCase() : "",
		});
	}

  // Add worker name column
  if (nameColumnSelected) {
    col.push({
      field: "name",
      headerName: "Worker Name",
      width: 200,
      headerAlign: "left",
      renderHeader: () => <ColumnHeader text={"Worker Name"} />,
      renderCell: (params) => (
        <ColumnEmployeeCell params={params} searchText={searchText} />
      ),
      valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
    });
  }

	if (totalScoreColumnSelected) {
		col.push({
			field: "skillScore",
			headerName: "Total Skill Score",
			width: 160,
			headerAlign: "left",
			renderHeader: () => <ColumnHeader text={"Total Skill Score"} />,
			renderCell: (params) => (
				<ColumnCell
					text={params.row.skillScore ?? "0"}
					searchText={searchText}
				/>
			),
		});
	}

	if (managerColumnSelected) {
		// Add reporting manager column
		col.push({
			field: "managerName",
			headerName: "Supervisor",
			width: 200,
			headerAlign: "left",
			renderHeader: () => <ColumnHeader text={"Supervisor"} />,
			renderCell: (params) => (
				<ColumnCell
					text={params.row.managerName ?? "-"}
					searchText={searchText}
				/>
			),
			valueGetter: (params) => params.row.managerName.toLowerCase(),
		});
	}

	if (workCenterColumnSelected) {
		col.push({
			field: "workCenter",
			headerName: "Work Center",
			width: 200,
			headerAlign: "left",
			renderHeader: () => <ColumnHeader text={"Work Center"} />,
			renderCell: (params) => (
				<ColumnCell
					text={params.row.workCenter ?? "-"}
					searchText={searchText}
				/>
			),
			valueGetter: (params) => params.row.workCenter,
		});
	}

	if (shiftPatternColumnSelected) {
		col.push({
			field: "shiftPatternName",
			headerName: "Shift Pattern",
			width: 200,
			headerAlign: "left",
			renderHeader: () => <ColumnHeader text={"Shift Pattern"} />,
			renderCell: (params) => (
				<ColumnCell
					text={params.row.shiftPatternName ?? "-"}
					searchText={searchText}
				/>
			),
			valueGetter: (params) => params.row.shiftPatternName,
		});
	}

	const skillsCol = createSkillColumn(
		jobIdsSkillsArr,
		handleSkillLevels,
		allSkillLevels,
		notAssignedSkillLevel,
		handleAddSkillInterested,
		isJobFilter,
		searchText,
		isColumnGroup,
		false,
		skillsUpdatedListInSession
	);

	col = [...col, ...skillsCol];
	if (!isColumnGroup) {
		// Remove duplicate skills when not with column grouping
		col = col.filter(
			(v, i, a) => a.findIndex((v2) => v2.field === v.field) === i
		);
	}
	rows = skillLevelIds.length ? filteredWorkersByLevel ?? [] : workers ?? [];

	const uniqueSkills = jobIdsSkillsArr.filter(
		(obj, index, self) =>
			index === self.findIndex((o) => o.skillId === obj.skillId)
	);

	//check other skills
	if (
		filteredSkills.length > uniqueSkills.length &&
		isColumnGroup &&
		!isWorkCenterFilter
	) {
		const distinctSkills = findOtherSkills(filteredSkills, jobIdsSkillsArr);
		const isOther = true;

		const skillsCol1 = createSkillColumn(
			distinctSkills,
			handleSkillLevels,
			allSkillLevels,
			notAssignedSkillLevel,
			handleAddSkillInterested,
			isJobFilter,
			searchText,
			isColumnGroup,
			isOther,
			skillsUpdatedListInSession
		);

		let obj = {};
		let temp = [];
		distinctSkills.forEach((sk) => {
			temp.push({ field: sk.skillId + sk.jobColor });
			obj.groupId = "000";
			obj.headerName = "Other Skills";
			obj.children = temp;
			obj.renderHeaderGroup = () => (
				<ColumnGroupJobHeader skill={sk} searchText={searchText} />
			);
		});
		if (Object.keys(obj).length > 0) columnGroup.push(obj);

		col = [...col, ...skillsCol1];
	}

	//create column for skills with no jobs for other skill's column group
	const skillsWithNoJobs = skillsWithoutJobs(allSkillsOfAllLocations, allJobs);
	if (
		skillsWithNoJobs.length > 0 &&
		!isJobFilter &&
		isColumnGroup &&
		!isWorkCenterFilter
	) {
		const columnsForOtherSkills = createSkillColumnWithoutJobs(
			skillsWithNoJobs,
			handleSkillLevels,
			allSkillLevels,
			notAssignedSkillLevel,
			handleAddSkillInterested,
			isJobFilter,
			searchText,
			skillsUpdatedListInSession,
			isSkillPriorityFilter,
			skillPriorityIds
		);
		col = [...col, ...columnsForOtherSkills];
	}

	// column for skills with no jobs
	if (jobIds.length > 0 && columnGroup.length > 0) {
		const jobsWithNoSkills = columnGroup.filter((x) => x.noSkill);
		jobsWithNoSkills.forEach((job) => {
			const noSkill = {
				skillId: -2,
				skillName: "No Skill ",
			};

			const noSkillColumn = [
				{
					field: `${job.groupId}-${noSkill.skillId}`,
					headerName: noSkill.skillName,
					width: 100,
					headerAlign: "center",
					renderHeader: () => <ColumnNoSkillHeader />,
				},
			];
			col = [...col, ...noSkillColumn];
		});
	}

	useEffect(() => {
		setLoading(!dataProcessed);
	}, [dataProcessed]);

	useEffect(() => {
		if (width > 1312) {
			let tempPinnedColumns = [];
			if (employeeIdColumnSelected) {
				tempPinnedColumns.push("companyEmployeeId");
			}
			if (jobTitleColumnSelected) {
				tempPinnedColumns.push("jobTitle");
			}
			tempPinnedColumns.push("name");
			if (totalScoreColumnSelected) {
				tempPinnedColumns.push("skillScore");
			}
			if (managerColumnSelected) {
				tempPinnedColumns.push("managerName");
			}
			if (workCenterColumnSelected) {
				tempPinnedColumns.push("workCenter");
			}
			if (shiftPatternColumnSelected) {
				tempPinnedColumns.push("shiftPatternName");
			}
			setPinnedColumns(tempPinnedColumns);
		} else {
			setPinnedColumns(["name"]);
		}
	}, [
		width,
		managerColumnSelected,
		employeeIdColumnSelected,
		allOrganizations,
		jobTitleColumnSelected,
		totalScoreColumnSelected,
		workCenterColumnSelected,
		shiftPatternColumnSelected,
	]);

	return (
		<Box
			sx={{
				"@media (max-width: 1312px)": {
					width: isDrawerOpen ? "calc(100vw - 288px)" : "calc(100vw - 112px)",
				},
				"@media (min-width: 1312px)": {
					width: filterStatus
						? isDrawerOpen
							? "calc(100vw - 569px)"
							: "calc(100vw - 392px)"
						: isDrawerOpen
						? "calc(100vw - 288px)"
						: "calc(100vw - 112px)",
				},
				height:
					height > 890
						? isSkillInsightsEnabled
							? "calc(100vh - 308px)"
							: "calc(100vh - 250px)"
						: "635px",
			}}
			minWidth={320}
		>
			<SortedDatagridPro6
				apiRef={apiRef}
				defaultSortField='name'
				key={`cross-skilling-datagrid-${isJobFilter}-${rows.length}`}
				pagination
				columnVisibilityModel={{
					skillScore: isSkillPriorityEnabled,
				}}
				slots={{
					loadingOverlay: LinearProgress,
				}}
				loading={
					fetchManageWorkersDataStatus === "pending" ||
					skillMatrixFiltersStatus === "pending" ||
					skillMatrixDefaultFiltersStatus === "pending" ||
					updateWorkerSkillLevelStatus === "pending" ||
					addSkillInterestStatus === "pending" ||
					rejectSkillInterestStatus === "pending" ||
					loading
				}
				pageSizeOptions={[25, 50, 100]}
				experimentalFeatures={{
					columnGrouping: isColumnGroup,
					lazyLoading: true,
				}}
				rows={rows}
				columns={col}
				disableRowSelectionOnClick
				columnGroupingModel={columnGroup}
				getRowHeight={() => 50} // Do not set to "auto" memory leak
				columnHeaderHeight={60}
				pinnedColumns={{
					left: [
						"companyEmployeeId",
						"jobTitle",
						"name",
						"managerName",
						"workCenter",
						"shiftPatternName",
						"skillScore",
					],
				}}
				sx={{
					[`& .${gridClasses.cell}`]: {
						py: 1,
					},
					"& .MuiDataGrid-columnHeader": {
						padding: 0,
					},
					"& .MuiDataGrid-columnHeaders": {
						overflow: "unset",
					},
					"& .MuiDataGrid-columnHeader:focus": {
						outline: "none",
					},
					"& .MuiDataGrid-row:hover": {
						backgroundColor: "white",
					},
					"& .MuiDataGrid-cell:hover": {
						backgroundColor: "#F8F8F8",
					},
					"& .MuiDataGrid-cell": {
						padding: 0,
					},
					"& .MuiDataGrid-cell:focus": {
						outline: "none",
					},
					"& .hideRightSeparator > .MuiDataGrid-columnSeparator": {
						display: "none",
					},
					"& .MuiDataGrid-pinnedColumnHeaders": {
						height: "100%",
						display: "flex",
						alignItems: "flex-start",
						justifyContent: "flex-end",
						boxShadow:
							"0px 3px 1px -2px rgba(0,0,0,0.0),0px 2px 2px 0px rgba(0,0,0,0.0),0px 1px 5px 0px rgba(0,0,0,0.12)",
					},
					"& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":
						{
							display: "block",
						},
					// width: "100%",
					// height: "100%",
					backgroundColor: "#fff",
					border: 0,
					"& .MuiTablePagination-root": {
						marginRight: "60px",
					},
				}}
				columnBuffer={5}
				columnThreshold={5}
				disableColumnMenu
			/>
		</Box>
	);
}
