import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";

import useSchedulerData from "../../../../controllers/use-scheduler-data";
import {
	cancelShiftWithReason,
	fetchCancelShiftReasons,
} from "../../../../store/scheduler-slice";

const initialState = {
	selectedReason: "",
	otherReason: "",
	messageToWorker: "",
};

const useShiftCancelReasonController = (
	shiftId: number,
	handleClose: () => void
) => {
	const dispatch = useDispatch();
	const {
		allJobsObj,
		cancelShiftReasons,
		cancelShiftReasonsStatus,
		cancelShiftReasonsErrorMessage,
		cancelShiftWithReasonStatus,
		cancelShiftWithReasonErrorMessage,
		shiftCancelReasonEnabled,
		shiftsData,
	} = useSchedulerData();

	const shiftData = shiftsData.find((shift: any) => shift.id === shiftId);
	const [cancelShiftForm, setCancelShiftForm] = useState(initialState);

	const formatedShiftDate = useCallback(() => {
		const formattedStartDate = format(
			new Date(shiftData?.startDate),
			"EEE, dd MMM h:mm a"
		);
		const formattedEndTime = format(new Date(shiftData?.endDate), "h:mm a");
		return `${formattedStartDate} - ${formattedEndTime}`;
	}, [shiftData]);

	const handleCancelShift = (shiftId: number) => {
		const payload = {
			shiftId,
			cancelReasonId:
				Number(cancelShiftForm.selectedReason) === -1 || cancelShiftForm.selectedReason === ""
					? null
					: cancelShiftForm.selectedReason,
			otherCancelReason: cancelShiftForm.selectedReason === "" ? null : cancelShiftForm.otherReason,
			cancelMsg: cancelShiftForm.messageToWorker,
		};
		dispatch(cancelShiftWithReason(payload));
	};

	useEffect(() => {
		if (cancelShiftReasonsStatus === "idle") {
			dispatch(fetchCancelShiftReasons());
		}
	}, [dispatch, cancelShiftReasonsStatus]);

	useEffect(() => {
		if (cancelShiftWithReasonStatus === "fulfilled") {
			handleClose();
		}
	}, [cancelShiftWithReasonStatus, handleClose]);

	return [
		{
			cancelShiftForm,
			allJobsObj,
			cancelShiftReasonsStatus,
			cancelShiftReasons,
			cancelShiftWithReasonStatus,
			shiftCancelReasonEnabled,
			shiftData,
		},
		{
			setCancelShiftForm,
			formatedShiftDate,
			handleCancelShift,
		},
	];
};

export default useShiftCancelReasonController;
