import { Box, Tooltip,Typography } from "@mui/material";
import React, { useEffect,useRef, useState } from "react";

import Highlight from "../components/Highlight";

interface MultiLineTooltipProps {
    text: string;
    searchText?: string;
}

const MultiLineTooltip: React.FC<MultiLineTooltipProps> = ({ text, searchText = "" }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const element = textRef.current;
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      const maxLines = 2;
      const maxHeight = lineHeight * maxLines;

      // Check if content overflows the maximum height
      setIsOverflowing(element.scrollHeight > maxHeight);
    }
  }, [text]);

  return (
    <Tooltip title={isOverflowing ? text : ""} placement="top" arrow>
      <Box
        ref={textRef}
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
          wordBreak: "break-word",
          lineHeight: "1.5em", // Adjust based on your font
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
            <Highlight value={text} searchText={searchText} />
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default MultiLineTooltip;
