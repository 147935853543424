import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../../../js/utils/api-client";
import {
	SKillInsight_Skill,
	SkillInsightReportSliceStateTypes,
	SkillsByLevelWorkerCountInsightModel,
} from "./insight-report-types";

const initialState: SkillInsightReportSliceStateTypes = {
	fetchSkillInsightReport: {
		status: "idle",
		errorMessage: "",
	},
	skillInsightReportData: [],

	fetchSkillInsightDetails: {
		status: "idle",
		errorMessage: "",
	},
	skillInsightDetailsData: {
		workersList: [],
		interestedWorkersList: [],
	},
	skillInsightPanelDetails: {
		isOpen: false,
		skillId: "",
		locations: [],
		selectedKPI: "",
	},
};

//Get all skill insight
export const getSkillInsightReport = createAsyncThunk(
	"skillInsightReport/getSkillInsightReport",
	async (payload, thunkAPI) => {
		try {
			const skillInsightResponse = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Insight/GetSkillInsightForUser/skillinsight`
			);

			return thunkAPI.fulfillWithValue(skillInsightResponse.data);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);

//Get skill insight details by skillId
export const fetchSkillInsightDetails = createAsyncThunk(
	"skillInsightReport/fetchSkillInsight",
	async (payload, thunkAPI) => {
		try {
			const skillDetails = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Insight/GetSkillInsightDetails/skillinsight/${payload}`
			);

			return thunkAPI.fulfillWithValue(skillDetails.data);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const SkillInsightReportSlice = createSlice({
	name: "skillInsightReport",
	initialState,
	reducers: {
		openSkillInsightDetails: (state, action) => {
			state.skillInsightPanelDetails.isOpen = true;
			state.skillInsightPanelDetails.skillId = action.payload.skillId;
			state.skillInsightPanelDetails.locations = action.payload.locations;
			state.skillInsightPanelDetails.selectedKPI =
				action.payload.selectedKPI ?? "";
		},
		closeSkillInsightDetails: (state) => {
			state.skillInsightPanelDetails.isOpen = false;
			state.skillInsightPanelDetails.skillId = "";
			state.skillInsightPanelDetails.locations = [];
		},
		setSelectedKPI: (state, action) => {
			state.skillInsightPanelDetails.selectedKPI = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSkillInsightReport.fulfilled, (state, action) => {
			state.fetchSkillInsightReport.status = "fulfilled";
			state.skillInsightReportData = action.payload.skills.map(
				(skill: SKillInsight_Skill) => {
					const workerCountByLevel =
						action.payload.skillsByLevelWorkerCounts.filter(
							(item: SkillsByLevelWorkerCountInsightModel) =>
								skill.skillId === item.skillId.toString()
						);
          const interestedWorkersLocationIds = skill.interestedWorkers.map(
						(worker: { locationId: number }) => worker.locationId
					);
					return {
						...skill,
						workerCountByLevel,
						locationIds: [
							...new Set([
								...interestedWorkersLocationIds,
								...skill.locationIds,
							]),
						],
					};
				}
			);
		});

		builder.addCase(getSkillInsightReport.rejected, (state, action) => {
			state.fetchSkillInsightReport.status = "error";
			state.fetchSkillInsightReport.errorMessage =
				action.payload ?? "An error occurred";
		});

		builder.addCase(getSkillInsightReport.pending, (state) => {
			state.fetchSkillInsightReport.status = "pending";
		});

		builder.addCase(fetchSkillInsightDetails.fulfilled, (state, action) => {
			state.fetchSkillInsightDetails.status = "fulfilled";
			state.skillInsightDetailsData = action.payload;
		});

		builder.addCase(fetchSkillInsightDetails.rejected, (state, action) => {
			state.fetchSkillInsightDetails.status = "error";
			state.fetchSkillInsightDetails.errorMessage =
				action.payload ?? "An error occurred";
		});

		builder.addCase(fetchSkillInsightDetails.pending, (state) => {
			state.fetchSkillInsightDetails.status = "pending";
		});
	},
});

export const {
	openSkillInsightDetails,
	closeSkillInsightDetails,
	setSelectedKPI,
} = SkillInsightReportSlice.actions;

export default SkillInsightReportSlice.reducer;
