import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { SkillRepresentationCell } from "../../../../../../../export/cross-skilling";
import { SkillLevel } from "../../../../../cross-skilling/types";
import { ALPHABET } from "./constants/level-representations/alphabet";
import { BOX } from "./constants/level-representations/box";
import { CIRCLE } from "./constants/level-representations/circle";
import { DONUT } from "./constants/level-representations/donut";
import { NUMBER } from "./constants/level-representations/number";

const CrossSkillingNoSkillsAssigned = ({
  skillRepresentationLevels,
  activeSkillRepresentationType,
  notAssignedSkillLevel,
  setNotAssignedSkillLevel,
  companyNotAssignedSkillLevel,
}: any) => {
  const isNameDisabled = () => {
    return activeSkillRepresentationType === "number" ||
      activeSkillRepresentationType === "alphabet"
      ? notAssignedSkillLevel?.value === "--" || notAssignedSkillLevel?.value == null
      : notAssignedSkillLevel?.repCode === "--" || notAssignedSkillLevel?.repCode == null;
  };
  const isCodeDisabled = () => {
      return activeSkillRepresentationType === "number" ||
      activeSkillRepresentationType === "alphabet"
          ? notAssignedSkillLevel?.value === "--" || notAssignedSkillLevel?.value == null
          : notAssignedSkillLevel?.repCode === "--" || notAssignedSkillLevel?.repCode == null;
  };
  const haveDuplicateName = (name: string) => {
    try {
      return (
        skillRepresentationLevels.find(
          (representationLevel: { name: string }) =>
            representationLevel.name === name
        ) !== undefined
      );
    } catch (e) {
      return false;
    }
  };
  const haveDuplicateValue = (value: string) => {
    try {
      return (
        skillRepresentationLevels.find(
          (representationLevel: { value: string }) =>
            representationLevel.value.toString() === value.toString()
        ) !== undefined
      );
    } catch (e) {
      return false;
    }
  };
  const gotErrorInName = (name: string) => {
    try {
      if (
        haveDuplicateName(notAssignedSkillLevel?.name) ||
        (notAssignedSkillLevel?.value?.trim() !== "" &&
          notAssignedSkillLevel?.value?.trim() !== "--" &&
          notAssignedSkillLevel?.name?.trim() === "")
      ) {
        return true;
      }
    } catch (e) {
      return false;
    }
  };
  const getRepCodeByRepType = (repType: string) => {
    switch (repType) {
      case "alphabet":
        return "alphabet-1";
      case "number":
        return "number-1";
      default:
        return "";
    }
  };
  const EmptySkillLevelMenuItem = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "25px",
        }}
      >
        --
      </Typography>
    </Box>
  );
  const EmptySkillLevelValueItem = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "10px",
        height: "10px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "25px",
          color: "#000",
        }}
      >
        --
      </Typography>
    </Box>
  );

  let reps: any[] = [];
  switch (activeSkillRepresentationType) {
    case "alphabet":
      reps = ALPHABET;
      break;
    case "circle":
      reps = CIRCLE;
      break;
    case "box":
      reps = BOX;
      break;
    case "donut":
      reps = DONUT;
      break;
    default:
      reps = NUMBER;
      break;
  }

  reps = reps?.filter(
    (rep) =>
      !skillRepresentationLevels?.some(
        (s: { repLevel: string }) => s?.repLevel === rep?.repLevel
      )
  );

  reps = [
    {
      name: "",
      level: -1,
      repLevel: "",
      repCode: "--",
      value: "--",
    },
    ...reps,
  ];

  if (
    (activeSkillRepresentationType === "circle" ||
      activeSkillRepresentationType === "box" ||
      activeSkillRepresentationType === "donut") &&
    notAssignedSkillLevel
  ) {
    if (!reps.find((r) => r.repCode === notAssignedSkillLevel.repCode) && companyNotAssignedSkillLevel) {
      setNotAssignedSkillLevel(companyNotAssignedSkillLevel);
    }
  }

  return (
    <Box
      width="100%"
      bgcolor="#FAFAFA"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={3}
      pt={1}
      pb={3}
    >
      <Box>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.15px",
            textAlign: "left",
          }}
        >
          Configure - No Skill Level Assigned
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.17px",
            textAlign: "left",
          }}
        >
          Representation of Skills which are not associated with any Skill level
        </Typography>
      </Box>
      <Box display="flex">
        <Box>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Name
          </Typography>
          <TextField
            variant="filled"
            // sx={{
            // 	width: "200px",

            // 	"& .MuiInputBase-root": {
            // 		height: "48px",
            // 		backgroundColor: "#FFFFFF",
            // 	},
            // }}
            inputProps={{
              "aria-label": "Without label",
              maxLength: 20,
              style: {
                padding: 16.5,
              },
            }}
            disabled={isNameDisabled()}
            value={notAssignedSkillLevel?.name ?? ""}
            onChange={(e) =>
              setNotAssignedSkillLevel((prev: any) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            error={gotErrorInName(notAssignedSkillLevel?.name)}
          />
        </Box>
        <Box ml={1}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Icon
          </Typography>
          {activeSkillRepresentationType === "number" ||
          activeSkillRepresentationType === "alphabet" ? (
            <TextField
              variant="filled"
              sx={{
                width: "70px",
                // "& .MuiInputBase-root": {
                // 	height: "48px",
                // 	backgroundColor: "#FFFFFF",
                // 	padding: "0px 5px 15px 5px",
                // },
              }}
              inputProps={{
                "aria-label": "Without label",
                maxLength: 2,
                style: {
                  padding: 16.5,
                  textTransform: "uppercase",
                },
              }}
              value={notAssignedSkillLevel?.value ?? "--"}
              onChange={(e) => {
                setNotAssignedSkillLevel((prev: any) => ({
                  ...prev,
                  icon: e.target.value,
                  value: e.target.value,
                  repCode: getRepCodeByRepType(activeSkillRepresentationType),
                }));
              }}
              error={
                haveDuplicateValue(notAssignedSkillLevel?.value) ||
                notAssignedSkillLevel?.value?.trim() === ""
              }
            />
          ) : (
            <Select
              variant="filled"
              // size='small'
              sx={{
                width: "70px",
                // backgroundColor: "#FFFFFF",
                // height: "55px",
                // padding: "0px 5px 15px 5px",
                // minWidth: "80px",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
              value={notAssignedSkillLevel?.repCode ?? "--"}
              renderValue={(repCode: any) => {
                // Check if the repCode is falsy, equals "--", or doesn't match any in reps
                if (
                  !repCode ||
                  repCode === "--" ||
                  !reps.find((r) => r.repCode === repCode)
                ) {
                  return EmptySkillLevelValueItem; // Render "--" when no valid selection is found
                } else {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "10px",
                      }}
                    >
                      <SkillRepresentationCell
                        level={repCode}
                        representationLevel={
                          reps.find((r) => r.repCode === repCode)?.repLevel
                        }
                        representationType={activeSkillRepresentationType}
                        height={10}
                        width={10}
                        backgroundWidth={"25px"}
                        backgroundHeight={"25px"}
                      />
                    </Box>
                  );
                }
              }}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (
                  selectedValue === "--" ||
                  !reps.find((r) => r.repCode === selectedValue)
                ) {
                  setNotAssignedSkillLevel((prev: any) => ({
                    ...prev,
                    repCode: "--",
                    value: "--",
                    icon: "--",
                  }));
                } else {
                  setNotAssignedSkillLevel((prev: any) => ({
                    ...prev,
                    repCode: selectedValue,
                    icon: selectedValue,
                  }));
                }
              }}
            >
              {reps.map((lev: any) =>
                !lev.repCode || lev.repCode === "--" ? (
                  <MenuItem key={lev.level} value={"--"}>
                    {EmptySkillLevelMenuItem}
                  </MenuItem>
                ) : (
                  <MenuItem key={lev.level} value={lev.repCode}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <SkillRepresentationCell
                        // level={lev.level}
                        representationLevel={lev.repLevel}
                        representationType={activeSkillRepresentationType}
                        // height={5}
                        // width={5}
                        backgroundWidth={"25px"}
                        backgroundHeight={"25px"}
                      />
                    </Box>
                  </MenuItem>
                )
              )}
            </Select>
          )}
        </Box>
        <Box ml={1}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Code
          </Typography>
          <TextField
            variant="filled"
            sx={{
              width: "60px",
              // "& .MuiInputBase-root": {
              // 	height: "48px",
              // 	backgroundColor: "#FFFFFF",
              // 	padding: "20px 20px",
              // },
            }}
            inputProps={{
              "aria-label": "Without label",
              maxLength: 2,
              style: {
                padding: 16.5,
                textTransform: "uppercase",
              },
            }}
            value={notAssignedSkillLevel?.value ?? "--"}
            disabled={isCodeDisabled()}
            onChange={(e) =>
              setNotAssignedSkillLevel((prev: any) => ({
                ...prev,
                value: e.target.value,
              }))
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CrossSkillingNoSkillsAssigned;
