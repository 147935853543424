import isAfter from "date-fns/isAfter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { fetchMaxWorkersByLocationJob } from "../../../../../../../js/features/ManageJobs/ManageJobsSlice";
import { calculateShiftHoursAndMinutes } from "../../../../../../utils/helpers";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import { SchedulerWorkCenter } from "../../../../store/types";
import {
	configureDatesOfTheWeek,
	getInitValues,
	yupInitObject,
} from "../utils";

type UseCnsDialogProps = {
	draftShiftDate: Date;
};

type JobRole = any;

type FormValues = {
	startTime: string;
	endTime: string;
	numberOfWorkersNeeded: string;
};

const useCnsDialog = ({ draftShiftDate }: UseCnsDialogProps) => {
	const dispatch = useDispatch();

	const [filteredJobs, setFilteredJobs] = useState<JobRole[]>([]);
	const {
		allJobRoles,
		filterJobRoles,
		filterAllWorkcenters,
		fetchFilterAndShiftDataStatus,
		defaultDurationHrs,
	} = useSchedulerData();

	const initialValues = useMemo(
		() =>
			getInitValues({
				draftShiftDate,
				defaultDurationHrs,
				shiftPurposeId: 0,
			}),
		[draftShiftDate, defaultDurationHrs]
	);

	const schema = useMemo(() => Yup.object(yupInitObject), []);

	const [totalHours, setTotalHours] = useState(0);
	const [totalMins, setTotalMins] = useState(0);
	const [initialShiftDateTimeValues, setInitialShiftDateTimeValue] =
		useState(initialValues);
	const [selectedDatesOfTheWeek, setSelectedDatesOfTheWeek] = useState(() =>
		configureDatesOfTheWeek(new Date(initialValues.startTime))
	);

	useEffect(() => {
		const initValues = getInitValues({
			draftShiftDate,
			defaultDurationHrs,
			shiftPurposeId: 0,
		});
		setInitialShiftDateTimeValue(initValues);
		setSelectedDatesOfTheWeek(
			configureDatesOfTheWeek(new Date(initValues.startTime))
		);
	}, [draftShiftDate, defaultDurationHrs]);

	const handleWorkcenterChange = (workcenterId: string) => {
		const numericWorkcenterId = parseInt(workcenterId);

		if (fetchFilterAndShiftDataStatus === "fulfilled") {
			const selectedWorkcenter = filterAllWorkcenters.find(
				(workcenter: SchedulerWorkCenter) =>
					workcenter.id === numericWorkcenterId
			);

			if (selectedWorkcenter) {
				const jobIdSet = new Set(
					selectedWorkcenter.jobIds.split(",").map(Number)
				);
				setFilteredJobs(
					filterJobRoles.filter(
						(job: JobRole) => jobIdSet.has(job.id) && job.isActive
					)
				);
			} else {
				setFilteredJobs([]);
			}
		} else {
			setFilteredJobs(
				allJobRoles.filter(
					(job: JobRole) =>
						job.workCenterIds.includes(workcenterId) && job.isActive
				)
			);
		}
	};

	const getNumberOfStaff = useCallback(
		(
			jobRole: string | null,
			locationId: string,
			startTime: string,
			endTime: string,
			shiftSkills?: any[]
		) => {
			if (!locationId || !startTime || !endTime) return;
			if (jobRole === "" && shiftSkills?.length === 0) return;

			dispatch(
				fetchMaxWorkersByLocationJob({
					locationId,
					jobId: jobRole === "" ? null : jobRole,
					startDateTime: startTime,
					endDateTime: endTime,
					shiftSkills,
				})
			);
		},
		[dispatch]
	);

	const calculateHours = (
		startTime: string,
		endTime: string,
		numberOfWorkersNeeded: string
	) => {
		const { hours, minutes } = calculateShiftHoursAndMinutes(
			startTime,
			endTime,
			numberOfWorkersNeeded
		);
		setTotalHours(hours);
		setTotalMins(minutes);
	};

	const calculateTotalHoursFromWorkers = (
		formValues: FormValues,
		numberOfWorkersNeeded: string
	) => {
		const { startTime, endTime } = formValues;
		if (startTime && endTime && numberOfWorkersNeeded) {
			calculateHours(startTime, endTime, numberOfWorkersNeeded);
		}
	};

	const calculateTotalHoursFromStartTime = (
		formValues: FormValues,
		startTime: string
	) => {
		const { numberOfWorkersNeeded, endTime } = formValues;
		if (startTime && endTime && numberOfWorkersNeeded) {
			calculateHours(startTime, endTime, numberOfWorkersNeeded);
		}
	};

	const calculateTotalHoursFromEndTime = (
		formValues: FormValues,
		endTime: string
	) => {
		const { numberOfWorkersNeeded, startTime } = formValues;
		if (startTime && endTime && numberOfWorkersNeeded) {
			calculateHours(startTime, endTime, numberOfWorkersNeeded);
		}
	};

	const isDisabled = (currentDate: Date, shiftStartDate: Date) => {
		return isAfter(shiftStartDate, currentDate) || false;
	};

	return [
		{
			initialValues,
			schema,
			initialShiftDateTimeValues,
			filteredJobs,
			selectedDatesOfTheWeek,
			totalHours,
			totalMins,
		},
		{
			isDisabled,
			calculateTotalHoursFromEndTime,
			calculateTotalHoursFromStartTime,
			calculateTotalHoursFromWorkers,
			handleWorkcenterChange,
			getNumberOfStaff,
			setInitialShiftDateTimeValue,
			setSelectedDatesOfTheWeek,
			setTotalHours,
		},
	] as const;
};

export default useCnsDialog;
