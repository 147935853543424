/* eslint-disable simple-import-sort/imports */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	closeSkillInsightDetails,
	fetchSkillInsightDetails,
} from "../slices/skill-insight-report-slice";
import {
	SkillInsightRootState,
	WorkerInsightRootState,
} from "../slices/insight-report-types";

const useSkillDetailsInsightController = ({ skillId }: { skillId: string }) => {
	const dispatch = useDispatch();

	const skillsReportData = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.skillInsightReportData
	);
	const fetchSkillInsightDetailsStatus = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.fetchSkillInsightDetails?.status
	);
	const allSkillLevels = useSelector(
		(state: WorkerInsightRootState) =>
			state.workerInsightReportData.skillsLevelLegendsData
	);
	const skillsInsightData = useSelector(
		(state: SkillInsightRootState) =>
			state.skillInsightReportData.skillInsightDetailsData
	);

	useEffect(() => {
		if (skillId !== null) {
			dispatch(fetchSkillInsightDetails(skillId));
		}
	}, [skillId, dispatch]);

	return [
		{
			skillsReportData,
			fetchSkillInsightDetailsStatus,
			allSkillLevels,
			skillsInsightData,
		},
		{ dispatch, closeSkillInsightDetails },
	];
};

export default useSkillDetailsInsightController;
